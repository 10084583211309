import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {genTextColumn} from '../../util/grid/grid-renderer.util';
import {I18n} from '../../services/i18n.service';
import {ExternalLink, CodeTablesService, CodeTableEntry, ProductListItem} from '../../api/core';
import {TranslateService} from '@ngx-translate/core';
import {first, Subscription} from 'rxjs';
import {CellValueChangedEvent, ColDef, GridApi, GridOptions, GridReadyEvent} from 'ag-grid-community';
import {genIconButtonColumn} from '../grid/cell-renderers/icon-button.renderer';

@Component({
  selector: 'app-external-links',
  templateUrl: './external-links.component.html'
})
export class ExternalLinksComponent implements OnInit, OnDestroy{

  linkColumns: ColDef[] = [
    {
      ...genIconButtonColumn({
        callback: (data: ExternalLink) => this.deleteRow(data),
        icon: 'delete',
        tooltip: this.translateService.instant('remove'),
      }),
      floatingFilter: false,
      colId: 'icon-button-delete',
    },
    {
      ...genTextColumn('url', I18n.getColName('url'), null),
      floatingFilter: false,
      suppressHeaderMenuButton: true,
      singleClickEdit: true,
      editable: true,
    },
    {
      ...genTextColumn('display', I18n.getColName('externalLinkDisplayText'), null),
      floatingFilter: false,
      suppressHeaderMenuButton: true,
      singleClickEdit: true,
      editable: true,
    },
    {
      ...genTextColumn('description', I18n.getColName('description'), null),
      floatingFilter: false,
      suppressHeaderMenuButton: true,
      singleClickEdit: true,
      editable: true,
    },
  ];
  linkGridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    readOnlyEdit: false,
    enableCellTextSelection: false,
    ensureDomOrder: true,
    stopEditingWhenCellsLoseFocus: true,
    onGridReady: (event: GridReadyEvent<ExternalLink>) => this.onGridReady(event),
    onCellValueChanged: (event: CellValueChangedEvent<ExternalLink>) => this.onCellValueChanged(event),
    //onCellEditRequest: (event) => this.handleCellEditRequest(event),
    //onModelUpdated: (event) => this.handleDetailsModelUpdated(event),
  };

  @Input()
  externalLinks: ExternalLink[] = [];
  @Output()
  externalLinksChanged: EventEmitter<ExternalLink[]> = new EventEmitter<ExternalLink[]>();

  subscriptions: Subscription[] = [];
  linkTypes: CodeTableEntry[] = [];
  editRowIndex: number | null = null;
  gridApi: GridApi<ExternalLink>;

  constructor(
    private readonly translateService: TranslateService,
    private readonly codeTablesService: CodeTablesService
  ) {

  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onGridReady(event: GridReadyEvent<ExternalLink>) {
    this.gridApi = event.api;
    this.codeTablesService.getCodeTableEntries("EXTERNAL_LINK_TYPE")
      .pipe(first())
      .subscribe((linkTypes) => {
        this.updateColumns(event.api, linkTypes);
        this.subscriptions.push(I18n.getColumns(this.translateService, event.api));
      });
  }

  addExternalLink() {
    this.onChanged([
      ...this.externalLinks,
      {
        url: 'https://google.com',
        display: '',
        description: '',
        type: this.linkTypes[0]
      }
    ], this.externalLinks.length);
  }

  private onChanged(externalLinks: ExternalLink[], rowIndex?: number) {
    if (rowIndex == null) {
      rowIndex = this.gridApi.getFocusedCell().rowIndex;
    }
    this.externalLinksChanged.emit([...(externalLinks || this.externalLinks)]);
    setTimeout(() => {
      this.gridApi.ensureIndexVisible(rowIndex);
    }, 0);
  }

  private updateColumns(api: GridApi, linkTypes: CodeTableEntry[]) {
    this.linkTypes = linkTypes;
    this.linkColumns.push({
      ...genTextColumn('type', I18n.getColName('type'),(d) => d.value.name),
      floatingFilter: false,
      suppressHeaderMenuButton: true,
      singleClickEdit: true,
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: (params) => this.cellEditorParams(params),
      cellClass: 'editable-cell',
    });
    api.setGridOption("columnDefs", this.linkColumns);
  }
  private cellEditorParams(params) {
    return {
      values: this.linkTypes.map((type) => type.name),
    };
  }
  private onCellValueChanged(event: CellValueChangedEvent<ExternalLink>) {
    switch (event.colDef.field) {
      case 'url':
        event.node.data.url = `${event.newValue}`;
        this.onChanged(this.externalLinks);
        break;
      case 'display':
        event.node.data.display = `${event.newValue}`;
        this.onChanged(this.externalLinks);
        break;
      case 'description':
        event.node.data.description = `${event.newValue}`;
        this.onChanged(this.externalLinks);
        break;
      case 'type':
        event.node.data.type = this.linkTypes.find(t => t.name === event.newValue);
        this.onChanged(this.externalLinks);
        break;
      default:
        break;
    }
  }
  private deleteRow(row: ExternalLink) {
    this.onChanged(this.externalLinks.filter(item => item !== row), 0);
  }
}
