export interface AdvancedFilterField {
  ident: string;
  name: string;
  active: boolean;
  min?: number;
  max?: number;
  suffix?: string;
  entries?: any[];
}

export interface IntRange {
  from: number;
  to: number;
}

export interface FilterClient {
  ageRange?: IntRange;
  gender?: Array<string>;
  domiciles?: Array<string>;
}

export interface FilterPortfolio {
  /**
   * Selected Portfolio types (if empty all)
   */
  portfolioType?: Array<string>;
  /**
   * Based on Modelportfolio (if empty all, NONE if not based on model portfolio)
   */
  model?: Array<string>;
  riskRange?: DoubleRange;
  returnRange?: DoubleRange;
  liquidityRange?: DoubleRange;
  referenceCurrency?: Array<string>;
  valueRange?: DoubleRange;
  bu?: Array<string>;
  waiveOfAdvice?: boolean | null;
  mifid?: Array<string>;
  fidleg?: Array<string>;
  qualifiedInvestor?: boolean | null;
  rmLocation?: Array<string>;
  rmMarket?: Array<string>;
  rmDepartment?: {key: string, value: string};
  manager?: Array<string>;
  advisor?: Array<string>;
  advisoryType?: Array<string>;
  serviceCenter?: Array<string>;
  businessDivision?: Array<string>;
  sustainabilityRating?: Array<string>;
  sustainabilityProfile?: Array<string>;
  riskBreachOnly?: boolean;
  excludeRiskBreach?: boolean;
  allowOptOut?: boolean | null;
  formHasErrors?: boolean;
}

export interface DateRange {
  from?: string;
  to?: string;
  expires?: string;
}

export interface FilterOrgPosition {
  positions: string[];
}

export interface FilterPosition {
  ids?: Array<string>;
  excludedIds?: Array<string>;
  assetClass?: Array<string>;
  assetSubClass?: Array<string>;
  assetType?: Array<string>;
  ratingMoody?: Array<string>;
  ratingSP?: Array<string>;
  sustainabilityRating?: Array<string>;
  ratingSourceLGT?: Array<string>;
  ranking?: Array<string>;
  nextCallDate?: DateRange;
  maturityDate?: DateRange;
  value?: DoubleRange;
  productRatingApac?: Array<string>;
  productRatingMe?: Array<string>;
  productInvestmentHorizon?: Array<string>;
  issuerName?: {key: string, value: string};
  formHasErrors?: boolean;
}

export interface WeightRangeRange {
  min?: number;
  max?: number;
}

export interface WeightRange {
  key?: string | null;
  range?: WeightRangeRange;
  weight?: WeightRange.WeightEnum | null;
}
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace WeightRange {
  export type WeightEnum = 'under' | 'balance' | 'over';
  export const WeightEnum = {
    Under: 'under' as WeightEnum,
    Balance: 'balance' as WeightEnum,
    Over: 'over' as WeightEnum,
  };
}

export interface AssetRangeRange {
  min?: number | null;
  max?: number | null;
}

export interface AssetRange {
  key?: string;
  name?: string;
  range?: AssetRangeRange;
  isValue?: boolean;
  formHasErrors?: boolean;
}

export interface FilterIntermediaries {
  excludeEWA?: boolean;
  excludeEAM?: boolean;
}

export type CompositeList<T> = {
  operator: 'and' | 'mor', // normal AND or multiple OR
  children: Array<T>
}

export interface FilterConfig {
  /**
   * Id
   */
  id?: number;
  client?: FilterClient;
  intermediaries?: FilterIntermediaries;
  portfolio?: FilterPortfolio;
  position?: FilterPosition;
  /**
   * List of Weight Range
   */
  currency?: CompositeList<WeightRange>;
  assetsInclude?: CompositeList<AssetRange>;
  assetsExclude?: CompositeList<AssetRange>;
  /**
   * List of Weight Range
   */
  assetClass?: CompositeList<WeightRange>;
  /**
   * List of Weight Range
   */
  region?: CompositeList<WeightRange>;
  /**
   * List of Weight Range
   */
  gics?: CompositeList<WeightRange>;
  /**
   * List of Org Position idents
   */
  orgPosition?: FilterOrgPosition;
  /**
   * Advanced Filter
   */
  advanced?: object;
}
