<div class="header-bar">
  <button class="choose-csv" mat-raised-button (click)="clickCSV('isin')">
    <app-config-icon iconIdentifier="upload_file"></app-config-icon>
    {{'uploadIsinList' | translate}}
  </button>
  <div class="selected-count">
    {{ 'selectedProducts' | translate}}:
    {{ selectedProducts.length }}
  </div>
  <input type="file" id="fileCSV" accept="text/csv" (change)="chooseCSV($event)"/>
  <input type="search" id="search-input" matInput placeholder="{{'search' | translate}}">
</div>
<app-grid
  #product_grid
  tableId="product-search-table"
  [columnDefs]="columnDefs"
  [gridOptions]="gridOptions"
  [data]="gridData"
></app-grid>
<mat-checkbox
  class="select-all-checkbox"
  #selectAllCheckbox
  color="primary"
  [checked]="allSelected"
  [indeterminate]="someSelected"
  [matTooltip]="selectTooltip | translate"
  (click)="toggleSelection()"
></mat-checkbox>
