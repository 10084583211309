import { Injectable, Injector } from '@angular/core';
import { TableStateUtil } from 'src/app/util/grid/table-state.util';

@Injectable({ providedIn: 'root' })
export class TablePresetFilterService {
  private tableStateUtil: TableStateUtil;

  constructor(injector: Injector) {
    this.tableStateUtil = new TableStateUtil(injector);
  }

  saveTargetState(filterModel: any, sortModel: any, stateId: string) {
    this.tableStateUtil.saveState(filterModel, sortModel, 0, stateId);
  }

  genSimpleEntityFilterModel(
    name: string,
    field: string,
    vals: string[],
    valueNames: string[] = null
  ): any {
    const filterModel = {};
    filterModel[name] = {
      field: field || name,
      filterType: 'set',
      type: 'equals',
      values: vals,
      names: valueNames || vals.join(','),
    };
    return filterModel;
  }

  genSimpleTextFilterModel(name: string, value: string, type: string): any {
    const filterModel = {};
    filterModel[name] = {
      type,
      filter: value,
      filterType: 'text',
    };
    return filterModel;
  }

  genSimpleNumberFilterModel(name: string, value: number, type: string): any {
    const filterModel = {};
    filterModel[name] = {
      type,
      filter: value,
      filterTo: null,
      filterType: 'number',
    };
    return filterModel;
  }

  mergePresetFilters(presetFilters: PresetFilter[]) {
    let filterModel = {};
    presetFilters.forEach(
      (presetFilter) =>
        (filterModel = { ...filterModel, ...presetFilter.filterModel })
    );
    const stateId = this.tableStateUtil.genStateId(filterModel, null, 0);
    return {
      stateId,
      filterModel,
    };
  }

  genFilter(filterLink: FilterLink): PresetFilter {
    let filterModel;
    switch (filterLink.filterType) {
      case 'ENTITY':
      case 'ENUM':
        filterModel = this.genSimpleEntityFilterModel(
          filterLink.name,
          filterLink.field,
          filterLink.value as string[],
          filterLink.valueNames
        );
        break;
      case 'NUMBER':
        filterModel = this.genSimpleNumberFilterModel(
          filterLink.name,
          filterLink.value as number,
          filterLink.type
        );
        break;
      default:
        filterModel = this.genSimpleTextFilterModel(
          filterLink.name,
          filterLink.value as string,
          filterLink.type
        );
        break;
    }
    const stateId = this.tableStateUtil.genStateId(filterModel, null, 0);
    return {
      stateId,
      filterModel,
    };
  }
}

export interface PresetFilter {
  stateId: string;
  filterModel: any;
}

export interface FilterLink {
  /**
   * The name of the field to apply the filter to
   */
  name: string;

  /**
   * The field to apply the filter to or null to use the name instead. This is only used for entityData and enum filters.
   */
  field?: string | null;

  /**
   * A string for text filters, a number for number filters or an array of string for entityData and enum filters
   */
  value: string | string[] | number;

  /**
   * An array of a human readable representation of each value for entityData and enum filters or null to use 'value' instead.
   */
  valueNames?: string[] | null;

  /**
   * The type of filter
   */
  filterType: FilterType;

  /**
   * 'equals', 'contains', 'greaterThan',etc
   * Can be left empty for entityData or enum filters
   */
  type?: string;
}
export type FilterType = 'ENUM' | 'ENTITY' | 'TEXT' | 'NUMBER';
