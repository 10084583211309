<app-grid-filter
  *ngIf="tableStateUtil && gridApi && gridFilterEnabled"
  [gridApi]="gridApi"
  [dialogTitle]="'filters' | translate"
  [filterModelSubject]="filterModelSubject"
></app-grid-filter>

<ag-grid-angular
  *ngIf="tableStateUtil && columnDefs && data"
  class="ag-theme-aspark"
  [class.bottom-bar-padding]="!pagination"
  [class.dialog-filter]="featureDialogFilters"
  rowModelType="serverSide"
  [serverSideInfiniteScroll]="true"
  [domLayout]="domLayout"
  [paginationPageSize]="paginationPageSizeNumber"
  [paginationAutoPageSize]="paginationAutoPageSize"
  [cacheBlockSize]="cacheBlockSize"
  [serverSideDatasource]="dataSource"
  [columnDefs]="columnDefs"
  [autoGroupColumnDef]="autoGroupColumnDef"
  [groupRowRendererParams]="groupRowRendererParams"
  [groupDisplayType]="groupDisplayType"
  [gridOptions]="gridOptions"
  [rowMultiSelectWithClick]="rowMultiSelectWithClick"
  [rowSelection]="rowSelection"
  [pagination]="pagination"
  [getLocaleText]="getLocaleText"
  (gridReady)="onGridReady($event)"
  (rowGroupOpened)="onRowGroupOpened($event)"
  (modelUpdated)="onModelUpdated($event)"
  (filterChanged)="resetExpandedRows()"
  (sortChanged)="resetExpandedRows()"
  (paginationChanged)="onPaginationChanged($event)"
  [floatingFiltersHeight]="floatingFiltersHeight"
>
</ag-grid-angular>

<ag-grid-angular
  *ngIf="tableStateUtil && columnDefs && rowData"
  class="ag-theme-aspark"
  [class.bottom-bar-padding]="!pagination"
  [class.dialog-filter]="featureDialogFilters"
  [paginationPageSize]="paginationPageSizeNumber"
  [paginationAutoPageSize]="paginationAutoPageSize"
  [columnDefs]="columnDefs"
  [autoGroupColumnDef]="autoGroupColumnDef"
  [groupRowRendererParams]="groupRowRendererParams"
  [groupDisplayType]="groupDisplayType"
  [rowData]="rowData"
  [gridOptions]="gridOptions"
  [rowMultiSelectWithClick]="rowMultiSelectWithClick"
  [rowSelection]="rowSelection"
  [pagination]="pagination"
  [getLocaleText]="getLocaleText"
  (gridReady)="onGridReady($event)"
  (firstDataRendered)="adjustPage($event)"
  (paginationChanged)="onPaginationChanged($event)"
  (filterChanged)="sortAndFilterListener($event)"
  (sortChanged)="sortAndFilterListener($event)"
  [floatingFiltersHeight]="floatingFiltersHeight"
>
</ag-grid-angular>
<!--Check if grid ready to avoid overlapping flickering icons at the top of the grid before grid is ready-->
<div class="grid-basic-toolbar neg-margin" *ngIf="!hideFooter && gridApi">
  <app-config-icon
    iconIdentifier="settings_overscan"
    [tooltip]="'autoSizeColWidths' | translate"
    (click)="resetColWidths()"
  ></app-config-icon>
  <app-config-icon
    iconIdentifier="settings_backup_restore"
    [tooltip]="'resetGrid' | translate"
    (click)="resetGrid()"
  >
  </app-config-icon>
  <mat-form-field
    class="pageSizeSelector"
    [matTooltip]="'changePaginationSize' | translate"
    *ngIf="columnDefs && data && pagination"
  >
    <mat-select
      (selectionChange)="onPaginationChange()"
      [(ngModel)]="paginationPageSize"
    >
      <mat-option
        *ngFor="let option of paginationOptions"
        [value]="option.key"
        >{{ option.label }}</mat-option
      >
    </mat-select>
  </mat-form-field>
</div>
