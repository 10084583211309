<div *ngIf="employee" class="page-content-container">
  <app-sub-header
    [header]="employee.name"
    [breadcrumbs]="['objects', 'employeeOverview']"
  >
  </app-sub-header>
  <app-tabs-panel
    [tabs]="tabs"
    [defaultTab]="defaultTab"
    (tabChanged)="onTabChanged($event)"
  ></app-tabs-panel>

  <div
    *ngIf="activeTab === tabs[0].text"
    class="employee-overview-container aspark-container-padding"
  >
    <app-employee-overview-details
      [employee]="employee"
      class="details"
    ></app-employee-overview-details>
    <app-employee-publication-types
      class="publication-types"
      [employeeId]="employee.id"
    >
    </app-employee-publication-types>
  </div>

  <div *ngIf="activeTab === tabs[1].text" class="employee-overview-container">
    <app-employee-overview-actions
      [employee]="employee"
    ></app-employee-overview-actions>
  </div>

  <div *ngIf="activeTab === tabs[2].text" class="employee-overview-container">
    <app-employee-overview-campaigns
      [employee]="employee"
    ></app-employee-overview-campaigns>
  </div>
</div>
