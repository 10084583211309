import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ConfigService} from './config.service';
import {IScatterChartDataPoint} from 'src/app/models/chart.model';
import {CustomData} from 'src/app/models/custom_data.model';
import {BarData, Campaign, CodeTableEntry, PieDataItem, ScatterDataItem, Story} from 'src/app/api/core';
import {EChartTypes} from '../util/enum';
import {FilterConfig} from '../models/filter.model';

export interface LoadedChart {
  scatter?: boolean;
  pieAssets?: boolean;
  pieCurrency?: boolean;
  pieGics?: boolean;
  pieRegion?: boolean;
  barAssets?: boolean;
  barCurrency?: boolean;
  barGics?: boolean;
  barRegion?: boolean;
}

/**
 * Service for handling all the data that is shared inside the app. Serves as a type of store/state
 */
@Injectable({providedIn: 'root'})
export class DataService {
  /**
   * Chart data source
   */
  private dataSource: BehaviorSubject<CustomData>;
  /**
   * Currently active chart data
   */
  data$: Observable<CustomData>;
  /**
   * Filter source
   */
  private filterSource: BehaviorSubject<FilterConfig>;
  /**
   * Currently active filter
   */
  filter$: Observable<FilterConfig>;
  /**
   * Source for loading status of chart data
   */
  private chartDataLoadingSource: BehaviorSubject<boolean>;
  /**
   * Current status of chart loading
   */
  chartDataLoading$: Observable<boolean>;
  /**
   * Source for campaign
   */
  private campaignSource: BehaviorSubject<Campaign>;
  /**
   * Current campaign
   */
  campaign$: Observable<Campaign>;
  /**
   * Source for story
   */
  private storySource: BehaviorSubject<Story>;
  /**
   * Current story
   */
  story$: Observable<Story>;
  /**
   * Source for reusable loading status
   */
  private loadingSource: BehaviorSubject<boolean>;
  /**
   * Current status of chart loading
   */
  loading$: Observable<boolean>;
  /**
   * Source for loaded chart
   */
  private loadedChartSource: BehaviorSubject<LoadedChart>;
  /**
   * Loaded Chart observable
   */
  loadedChart$: Observable<LoadedChart>;
  /**
   * Source for favorite currencies
   */
  private favoriteCurrenciesSource: BehaviorSubject<CodeTableEntry[]>;
  /**
   * Favorite currencies observable
   */
  favoriteCurrencies$: Observable<CodeTableEntry[]>;

  constructor(
    protected configService: ConfigService,
  ) {
    // initialize all sources
    this.dataSource = new BehaviorSubject(null);
    this.data$ = this.dataSource.asObservable();
    this.filterSource = new BehaviorSubject(null);
    this.filter$ = this.filterSource.asObservable();
    this.chartDataLoadingSource = new BehaviorSubject(false);
    this.chartDataLoading$ = this.chartDataLoadingSource.asObservable();
    this.campaignSource = new BehaviorSubject(null);
    this.campaign$ = this.campaignSource.asObservable();
    this.storySource = new BehaviorSubject(null);
    this.story$ = this.storySource.asObservable();
    this.loadingSource = new BehaviorSubject(false);
    this.loading$ = this.loadingSource.asObservable();
    this.loadedChartSource = new BehaviorSubject<LoadedChart>({});
    this.loadedChart$ = this.loadedChartSource.asObservable();
    this.favoriteCurrenciesSource = new BehaviorSubject<CodeTableEntry[]>([]);
    this.favoriteCurrencies$ = this.favoriteCurrenciesSource.asObservable();
  }

  /**
   * Updates currently active chart data
   * @param data New chart data
   * @param merge with old data
   */
  updateData(data?: CustomData, merge: boolean = false): void {
    if (data?.scatter?.length) {
      data.scatter.forEach((d: IScatterChartDataPoint & ScatterDataItem) => {
        if (d.risk) {
          d.risk *= 100;
        }
        if (d.return) {
          d.return *= 100;
        }
      });
    }
    if (merge) {
      const oldData = this.dataSource.getValue();
      data = {
        ...oldData,
        ...data,
        scatter: (data?.scatter?.length) ? data.scatter : oldData?.scatter || [],
        pie: {
          assetClass: this.mergePie(oldData?.pie?.assetClass, data?.pie?.assetClass),
          currency: this.mergePie(oldData?.pie?.currency, data?.pie?.currency),
          region: this.mergePie(oldData?.pie?.region, data?.pie?.region),
          gics: this.mergePie(oldData?.pie?.gics, data?.pie?.gics),
        },
        bar: {
          assetClass: this.mergeBar(oldData?.bar?.assetClass, data?.bar?.assetClass),
          currency: this.mergeBar(oldData?.bar?.currency, data?.bar?.currency),
          region: this.mergeBar(oldData?.bar?.region, data?.bar?.region),
          gics: this.mergeBar(oldData?.bar?.gics, data?.bar?.gics),
        },
        statistics: {
          filteredPortfolios: data?.statistics?.filteredPortfolios || oldData?.statistics?.filteredPortfolios,
          filteredVolume: data?.statistics?.filteredVolume || oldData?.statistics?.filteredVolume,
          totalVolume: data?.statistics?.totalVolume || oldData?.statistics?.totalVolume,
          totalPortfolios: data?.statistics?.totalPortfolios || oldData?.statistics?.totalPortfolios,
        }
      };
    }
    this.loadedChartSource.next(this.getLoadedChart(data));
    this.dataSource.next(data);
  }

  mergePie(source: Array<PieDataItem>, target: Array<PieDataItem>): Array<PieDataItem> {
    return (target && target.length) ? target : source || [];
  }

  mergeBar(source: BarData, target: BarData): BarData {
    const res: BarData = {
      categories: null,
      overweight: null,
      balanced: null,
      underweight: null,
      overweightSelected: null,
      balancedSelected: null,
      underweightSelected: null,
    };
    Object.entries(target || {}).forEach(([key, val]) => {
      res[key] = (val == null) ? source[key] : val;
    });
    return res;
  }

  resetLoadedChart() {
    this.loadedChartSource.next({});
  }

  getLoadedChart(data?: CustomData): LoadedChart {
    return {
      scatter: !!data?.scatter?.length,
      pieAssets: !!data?.pie?.assetClass?.length,
      pieCurrency: !!data?.pie?.currency?.length,
      pieRegion: !!data?.pie?.region?.length,
      pieGics: !!data?.pie?.gics?.length,
      barAssets: data?.bar?.assetClass && Object.values(data?.bar?.assetClass).every(v => v != null),
      barCurrency: data?.bar?.currency && Object.values(data?.bar?.currency).every(v => v != null),
      barGics: data?.bar?.gics && Object.values(data?.bar?.gics).every(v => v != null),
      barRegion: data?.bar?.region && Object.values(data?.bar?.region).every(v => v != null),
    };
  }

  /**
   * Updates currently active filter
   * @param filter New filter id
   */
  updateFilter(filter: FilterConfig): void {
    this.filterSource.next(filter);
  }

  /**
   * Updates loading chart data status
   * @param loading Loading flag
   */
  updateChartDataLoading(loading: boolean): void {
    this.chartDataLoadingSource.next(loading);
  }

  /**
   * Updates campaign
   * @param campaign campaign
   */
  updateCampaign(campaign: Campaign): void {
    this.campaignSource.next(campaign);
  }

  /**
   * Updates story
   * @param story story
   */
  updateStory(story: Story): void {
    this.storySource.next(story);
  }

  /**
   * Updates loading status
   * @param loading Loading flag
   */
  updateLoading(loading: boolean): void {
    this.loadingSource.next(loading);
  }

  /**
   * Updates favorite currencies
   * @param currencies Favorite currencies list
   */
  updateFavoriteCurrencies(currencies: CodeTableEntry[]): void {
    this.favoriteCurrenciesSource.next(currencies);
  }

  getStatsOptions(selectedChart: EChartTypes, noScatter: boolean = false): string[] {
    return [
      noScatter ? 'noScatter' : null,
      selectedChart === EChartTypes.assetClassPie ? 'pieAssets' : null,
      selectedChart === EChartTypes.currencyPie ? 'pieCurrency' : null,
      selectedChart === EChartTypes.sectorPie ? 'pieGics' : null,
      selectedChart === EChartTypes.regionPie ? 'pieRegion' : null,
      selectedChart === EChartTypes.assetClassBar ? 'barAssets' : null,
      selectedChart === EChartTypes.currencyBar ? 'barCurrency' : null,
      selectedChart === EChartTypes.sectorBar ? 'barGics' : null,
      selectedChart === EChartTypes.regionBar ? 'barRegion' : null,
    ].filter(Boolean);
  }

  shouldChartLoad(cType: EChartTypes, loadedChart: LoadedChart): boolean {
    if (!cType) {
      return false;
    }
    switch (cType) {
      case EChartTypes.assetClassPie:
        if (loadedChart.pieAssets) {
          return false;
        }
        break;
      case EChartTypes.currencyPie:
        if (loadedChart.pieCurrency) {
          return false;
        }
        break;
      case EChartTypes.sectorPie:
        if (loadedChart.pieGics) {
          return false;
        }
        break;
      case EChartTypes.regionPie:
        if (loadedChart.pieRegion) {
          return false;
        }
        break;
      case EChartTypes.assetClassBar:
        if (loadedChart.barAssets) {
          return false;
        }
        break;
      case EChartTypes.currencyBar:
        if (loadedChart.barCurrency) {
          return false;
        }
        break;
      case EChartTypes.sectorBar:
        if (loadedChart.barGics) {
          return false;
        }
        break;
      case EChartTypes.regionBar:
        if (loadedChart.barRegion) {
          return false;
        }
        break;
    }
    return true;
  }
}
