import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl,} from '@angular/platform-browser';
import {Campaign, CampaignStatus} from 'src/app/api/core';
import {IKeyValue} from 'src/app/models/key-value.model';
import {GlobalService} from 'src/app/services/global.service';
import {PermissionService} from 'src/app/services/permission.service';
import {
  EAdditionalCardState,
  EClientFilter,
  EFilterCategories,
  EIntermediaryFilter,
  EPortfolioFilter,
  EPositionFilter,
  ERangeWeightFilter,
  ESuitabilityStatus,
} from 'src/app/util/enum';
import {INestedMenuItem} from '../models/menu.model';
import {UiFilterConfigService} from '../services/ui-filter-config.service';
import {EProtectedActions} from '../util/protected-actions';

/*
 * Return the value or 0 if null
 */
@Pipe({ name: 'valueOrZero' })
export class ValueOrZeroPipe implements PipeTransform {
  transform(value: number): number {
    return value ? value : 0;
  }
}

/**
 * Returns the decimal representation of a number
 */
@Pipe({ name: 'toDecimal' })
export class ToDecimalPipe implements PipeTransform {
  transform(value: number, decimals = 0): string {
    return value == null
      ? '-'
      : Intl.NumberFormat('de-CH', {
          style: 'decimal',
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals,
        }).format(value);
  }
}

/**
 * Returns the boolean representation of a number
 */
@Pipe({ name: 'toBoolean' })
export class ToBooleanPipe implements PipeTransform {
  transform(
    value: boolean,
    okValue: string = 'true',
    noValue: string = 'false'
  ): string {
    return value ? okValue : noValue;
  }
}
/**
 * Returns the value rounded to the provided number of decimals
 */
@Pipe({ name: 'toRounded' })
export class ToRoundedPipe implements PipeTransform {
  transform(value: number, count: number): number {
    return value == null
      ? null
      : Math.round((value + Number.EPSILON) * Math.pow(10, count)) /
          Math.pow(10, count);
  }
}

/**
 * Returns the fixed representaion of a number
 */
@Pipe({ name: 'toFixed' })
export class ToFixed implements PipeTransform {
  transform(value: number, fixedCount: number): string {
    return value.toFixed(fixedCount);
  }
}

/**
 * Returns the truncated value of a number
 */
@Pipe({ name: 'truncateValue' })
export class TruncateValuePipe implements PipeTransform {
  transform(value: number, decimals = 0): string {
    if (value == null) {
      return '-';
    }

    if (value > 999999) {
      return (
        new Intl.NumberFormat('de-CH', {
          style: 'decimal',
          maximumFractionDigits: decimals,
        }).format(value / 1000000) + 'M'
      );
    } else if (value > 999) {
      return (
        new Intl.NumberFormat('de-CH', {
          style: 'decimal',
          maximumFractionDigits: decimals,
        }).format(value / 1000) + 'k'
      );
    } else {
      return new Intl.NumberFormat('de-CH', {
        style: 'decimal',
        maximumFractionDigits: 0,
      }).format(value);
    }
  }
}

/**
 * Returns the truncated suffix of a value
 */
@Pipe({ name: 'truncateOnlySuffix' })
export class TruncateOnlySuffixPipe implements PipeTransform {
  transform(value: number): string {
    if (value > 999999) {
      return 'M';
    } else if (value > 999) {
      return 'k';
    } else {
      return '';
    }
  }
}

/**
 * Returns the truncated value of a number
 */
@Pipe({ name: 'truncateHasDecimals' })
export class TruncateHasDecimals implements PipeTransform {
  transform(value: number): number {
    if (value < 1000) {
      return 0;
    } else {
      return 1;
    }
  }
}

/**
 * Returns the formatted representation of the provided percentage
 */
@Pipe({ name: 'formatPercentage' })
export class FormatPercentagePipe implements PipeTransform {
  transform(value: number, multiply: boolean, addPlusSign?: boolean): string {
    if (value == null) {
      return '-';
    }
    const perc = multiply ? value * 100 : value;
    return perc < 0
      ? perc.toFixed(2) + '%'
      : addPlusSign
      ? '+' + perc.toFixed(2) + '%'
      : perc.toFixed(2) + '%';
  }
}

/**
 * Returns the formatted representation of a delta
 */
@Pipe({ name: 'formatDelta' })
export class FormatDeltaPipe implements PipeTransform {
  transform(value: number): string {
    if (value == null) {
      return '';
    }
    return value < 0
      ? Intl.NumberFormat('de-CH', {
          style: 'decimal',
          maximumFractionDigits: 0,
        }).format(value)
      : '+' +
          Intl.NumberFormat('de-CH', {
            style: 'decimal',
            maximumFractionDigits: 0,
          }).format(value);
  }
}

/**
 * Returns the color class for the provided percentage
 */
@Pipe({ name: 'percentageColorClass' })
export class PercentageColorClassPipe implements PipeTransform {
  transform(value: number): string {
    if (value == null) {
      return '';
    }
    return value < 0 ? 'color-error' : value === 0 ? '' : 'color-success';
  }
}

/**
 * Returns the trending icon for the provided percentage
 */
@Pipe({ name: 'percentageTrendingIcon' })
export class PercentageTrendingIconPipe implements PipeTransform {
  transform(value: number): string {
    if (value == null) {
      return '';
    }
    return value < 0
      ? 'trending_down'
      : value === 0
      ? 'trending_flat'
      : 'trending_up';
  }
}

/**
 * Returns the icon for the provided suitability
 */
@Pipe({ name: 'suitabilityIcon' })
export class SuitabilityIconPipe implements PipeTransform {
  transform(value: string): string {
    if (value == null) {
      return 'help_outline';
    }
    switch (value) {
      case ESuitabilityStatus.fail:
        return 'report';
      case ESuitabilityStatus.ok:
        return 'check_circle_outline';
      case ESuitabilityStatus.warning:
        return 'warning';
      default:
        return '';
    }
  }
}

/**
 * Returns the color class for the provided suitability
 */
@Pipe({ name: 'suitabilityColorClass' })
export class SuitabilityColorClassPipe implements PipeTransform {
  transform(value: string): string {
    if (value == null) {
      return '';
    }
    switch (value) {
      case ESuitabilityStatus.fail:
        return 'color-danger';
      case ESuitabilityStatus.ok:
        return 'color-success';
      case ESuitabilityStatus.warning:
        return 'color-accent-warning';
      default:
        return '';
    }
  }
}

/**
 * Computes the color class for a rate
 */
@Pipe({ name: 'rateColorClass' })
export class RateColorClassPipe implements PipeTransform {
  transform(rate: number, good: number, bad: number): string {
    if (rate === undefined) {
      return '';
    }

    return rate >= good ? 'color-success' : rate <= bad ? 'color-danger' : '';
  }
}

/**
 * Returns de-CH number local
 */
@Pipe({ name: 'numberLocaleDeCH' })
export class NumberLocalDeChPipe implements PipeTransform {
  transform(value: number): string {
    if (value == null) {
      return '-';
    }
    return Intl.NumberFormat('de-CH', {
      style: 'decimal',
      maximumFractionDigits: 0,
    }).format(value);
  }
}

/**
 * Returns the full text fopr tooltip if it was truncated
 */
@Pipe({ name: 'getTruncatedTextTooltip' })
export class GetTruncatedTextTooltipPipe implements PipeTransform {
  transform(text: string, length: number): string {
    return text.length > length ? text : '';
  }
}

@Pipe({ name: 'safeUrl' })
export class SafeUrlPipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  transform(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}

/**
 * Pipe to get health check icon
 */
@Pipe({ name: 'getHealthCheckIcon' })
export class GetHealthCheckIconPipe implements PipeTransform {
  transform(status: string): string {
    switch (status) {
      case 'ok':
        return 'check_circle_outline';
      case 'nok':
        return 'report';
      case 'warning':
        return 'warning';
      default:
        return 'help_outline';
    }
  }
}

/**
 * Pipe to get health check icon class
 */
@Pipe({ name: 'getHealthCheckIconClass' })
export class GetHealthCheckIconClassPipe implements PipeTransform {
  transform(status: string): string {
    switch (status) {
      case 'ok':
        return 'color-success';
      case 'nok':
        return 'color-danger';
      case 'warning':
        return 'color-accent-warning';
      default:
        return '';
    }
  }
}

/**
 * saft pipe to transform urls
 */
@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  transform(
    value: string,
    type: string
  ): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {
      case 'html':
        return this.sanitizer.bypassSecurityTrustHtml(value);
      case 'style':
        return this.sanitizer.bypassSecurityTrustStyle(value);
      case 'script':
        return this.sanitizer.bypassSecurityTrustScript(value);
      case 'url':
        return this.sanitizer.bypassSecurityTrustUrl(value);
      case 'resourceUrl':
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }
  }
}

/**
 * Pipe to get isin (simple isin or fund multi-isin)
 */
@Pipe({ name: 'getPersonalCampaignStatus' })
export class GetPersonalCampaignStatusPipe implements PipeTransform {
  constructor(protected globalService: GlobalService) {}

  transform(responsible: { [key: string]: string }): string {
    // TODO auth: is this should be moved to the backend?
    const username = this.globalService.getCurrentUserId();
    const entry = Object.entries(responsible).find((e) => e[0] === username);
    return entry ? entry[1] : '-';
  }
}

/**
 * Pipe to get isin (simple isin or fund multi-isin)
 */
@Pipe({ name: 'getPersonalCampaignStatusClass' })
export class GetPersonalCampaignStatusClassPipe implements PipeTransform {
  constructor(protected globalService: GlobalService) {}

  transform(responsible: { [key: string]: string }): string {
    // TODO auth: is this should be moved to the backend?
    const username = this.globalService.getCurrentUserId();
    const entry = Object.entries(responsible).find((e) => e[0] === username);
    if (entry && entry[1] === 'toProcess') {
      return 'row-to-process';
    } else {
      return null;
    }
    return entry ? entry[1] : '-';
  }
}

/**
 * Returns icon for additional card action
 */
@Pipe({ name: 'getPortfolioAdditionalCardActionIcon' })
export class GetPortfolioAdditionalCardActionIconPipe implements PipeTransform {
  transform(state: EAdditionalCardState): string {
    switch (state) {
      case EAdditionalCardState.charts:
        return '';
      // return 'table_chart';
      case EAdditionalCardState.portfolios:
        return 'insert_chart_outlined';
      case EAdditionalCardState.assetClass:
      case EAdditionalCardState.currency:
      case EAdditionalCardState.region:
      case EAdditionalCardState.sector:
        return 'cancel';
      default:
        return '';
    }
  }
}

/**
 * Pipe to check permission on action
 */
@Pipe({ name: 'protectedAction' })
export class ProtectedActionPipe implements PipeTransform {
  constructor(protected permissionService: PermissionService) {}
  transform(action: EProtectedActions): boolean {
    return this.permissionService.hasAnyPermission(action);
  }
}

@Pipe({ name : 'decentralizedAction'})
export class DecentralizedActionPipe implements PipeTransform {
  constructor(protected permissionService: PermissionService) {}
  transform(value: Campaign, decentralizedAction: EProtectedActions, centralizedAction: EProtectedActions): boolean {
    return this.permissionService.hasAnyPermission(
      (value as any).decentralized
        ? decentralizedAction
        : centralizedAction
    );
  }
}

/**
 * Pipe to check permission on route
 */

@Pipe({ name: 'protectedRoute' })
export class ProtectedRoutePipe implements PipeTransform {
  constructor(protected permissionService: PermissionService) {}
  transform(protectedActions: EProtectedActions[]): boolean {
    return this.permissionService.hasRouteAccess(protectedActions);
  }
}

/**
 * Pipe to check if campaign is editable in campaign overview
 */

@Pipe({ name: 'isCampaignEditable' })
export class IsCampaignEditablePipe implements PipeTransform {
  constructor() {}
  transform(status: CampaignStatus): boolean {
    return ![
      CampaignStatus.CLOSED,
      CampaignStatus.TERMINATED,
    ].includes(status);
  }
}

/**
 * Pipe to check if campaign is editable in campaign overview
 */

@Pipe({ name: 'getPdfOfForm' })
export class GetPdfOfFormPipe implements PipeTransform {
  constructor() {}
  transform(rawFormValue: any): string {
    return rawFormValue.getRawValue()?.pdf;
  }
}

/**
 * Pipe to check if campaign is frozen
 */

@Pipe({ name: 'isCampaignFrozen' })
export class IsCampaignFrozenPipe implements PipeTransform {
  constructor() {}
  transform(status: CampaignStatus): boolean {
    return status === CampaignStatus.FROZEN;
  }
}

/**
 * Pipe to check if campaign is launched
 */

@Pipe({ name: 'isCampaignLaunched' })
export class IsCampaignLaunchedPipe implements PipeTransform {
  constructor() {}
  transform(status: CampaignStatus): boolean {
    return status === CampaignStatus.LAUNCHED;
  }
}

/**
 * Pipe to check if renderer is hidden
 */

@Pipe({ name: 'isRendererHidden' })
export class IsRendererHiddenPipe implements PipeTransform {
  constructor() {}
  transform(hiddenFunc: (data: any) => boolean, data: any): boolean {
    return hiddenFunc && hiddenFunc(data);
  }
}

/**
 * Pipe to check if icon renderer icon is available
 */

@Pipe({ name: 'iconRendererIcon' })
export class IconRendererIconPipe implements PipeTransform {
  constructor() {}
  transform(icon: string | ((data: any) => string), data: any): string {
    if (typeof icon === 'function') {
      return icon(data);
    } else {
      return icon;
    }
  }
}

/**
 * Pipe to check if icon renderer icon is available
 */

@Pipe({ name: 'iconRendererIconClass' })
export class IconRendererIconClassPipe implements PipeTransform {
  constructor() {}
  transform(iconClass: string | ((data: any) => string), data: any): string {
    if (typeof iconClass === 'function') {
      return iconClass(data);
    } else {
      return iconClass;
    }
  }
}

/**
 * Pipe to check if icon renderer icon is available
 */

@Pipe({ name: 'rendererTooltip' })
export class RendererTooltipPipe implements PipeTransform {
  constructor() {}
  transform(tooltip: string | ((data: any) => string), data: any): string {
    if (typeof tooltip === 'function') {
      return tooltip(data);
    } else {
      return tooltip;
    }
  }
}

/**
 * Pipe to check if icon renderer icon is available
 */

@Pipe({ name: 'showTableSelectionToolbarActions' })
export class ShowTableSelectionToolbarActionsPipe implements PipeTransform {
  constructor(protected protectedActionPipe: ProtectedActionPipe) {}
  transform(campaign: Campaign): boolean {
    if (
      campaign.status === CampaignStatus.FROZEN &&
      !this.protectedActionPipe.transform(
        EProtectedActions.deletePortfolioClientCampaign
      ) &&
      !this.protectedActionPipe.transform(
        EProtectedActions.refreshSuitabilityCampaign
      )
    ) {
      return false;
    }
    if (
      campaign.status === CampaignStatus.LAUNCHED &&
      !this.protectedActionPipe.transform(
        EProtectedActions.executeActionCampaign
      ) &&
      !this.protectedActionPipe.transform(
        EProtectedActions.setNoActionCampaign
      ) &&
      !this.protectedActionPipe.transform(
        EProtectedActions.revertActionToPendingCampaign
      ) &&
      !this.protectedActionPipe.transform(
        EProtectedActions.assignToAdvisorCampaign
      ) &&
      !this.protectedActionPipe.transform(
        EProtectedActions.assignToRmCampaign
      ) &&
      !this.protectedActionPipe.transform(
        EProtectedActions.refreshSuitabilityCampaign
      )
    ) {
      return false;
    }
    return true;
  }
}

@Pipe({ name: 'getFilterLabel' })
export class GetFilterLabelPipe implements PipeTransform {
  constructor() {}
  transform(key: string, labels: IKeyValue[]): string {
    return labels.find((item) => item.key === key)?.value;
  }
}

@Pipe({ name: 'isFilterCategoryActive' })
export class IsFilterCategoryActivePipe implements PipeTransform {
  constructor(private readonly filterConfigService: UiFilterConfigService) {}
  transform(category: EFilterCategories): boolean {
    const config = this.filterConfigService.getConfigFlags();
    switch (category) {
      case EFilterCategories.client:
        return config.client.active;
      case EFilterCategories.intermediaries:
        return config.intermediaries.active;
      case EFilterCategories.portfolio:
        return config.portfolio.active;
      case EFilterCategories.position:
        return config.position.active;
      case EFilterCategories.assets:
        return config.asset.active;
      case EFilterCategories.assetClass:
        return config.assetClass.active;
      case EFilterCategories.currency:
        return config.currency.active;
      case EFilterCategories.region:
        return config.region.active;
      case EFilterCategories.sector:
        return config.sector.active;
      case EFilterCategories.orgPosition:
        return config.orgPositions.active;
      case EFilterCategories.advanced:
        return config.advanced.active;
      default:
        return false;
    }
  }
}

@Pipe({ name: 'isFilterPositionFilterActive' })
export class IsFilterPositionFilterActivePipe implements PipeTransform {
  constructor(private readonly filterConfigService: UiFilterConfigService) {}
  transform(filter: EPositionFilter): boolean {
    const config = this.filterConfigService.getConfigFlags();
    switch (filter) {
      case EPositionFilter.assetClass:
        return config.position.filters.assetClass;
      case EPositionFilter.assetSubClass:
        return config.position.filters.assetSubClass;
      case EPositionFilter.assetType:
        return config.position.filters.assetType;
      case EPositionFilter.excludeIds:
        return config.position.filters.excludeIds;
      case EPositionFilter.ids:
        return config.position.filters.ids;
      case EPositionFilter.nextCallDate:
        return config.position.filters.nextCallDate;
      case EPositionFilter.ranking:
        return config.position.filters.ranking;
      case EPositionFilter.ratingMoody:
        return config.position.filters.ratingMoody;
      case EPositionFilter.ratingSP:
        return config.position.filters.ratingSP;
      case EPositionFilter.ratingSourceLGT:
        return config.position.filters.ratingSourceLGT;
      case EPositionFilter.sustainabilityRating:
        return config.position.filters.sustainabilityRating;
      case EPositionFilter.productRatingApac:
        return config.position.filters.productRatingApac;
      case EPositionFilter.productRatingMe:
        return config.position.filters.productRatingMe;
      case EPositionFilter.productInvestmentHorizon:
        return config.position.filters.productInvestmentHorizon;
      case EPositionFilter.maturityDate:
        return config.position.filters.maturityDate;
      case EPositionFilter.value:
        return config.position.filters.value;
      case EPositionFilter.issuerName:
        return config.position.filters.issuerName;
      default:
        return false;
    }
  }
}

@Pipe({ name: 'isFilterPortfolioFilterActive' })
export class IsFilterPortfolioFilterActivePipe implements PipeTransform {
  constructor(private readonly filterConfigService: UiFilterConfigService) {}
  transform(filter: EPortfolioFilter): boolean {
    const config = this.filterConfigService.getConfigFlags();
    switch (filter) {
      case EPortfolioFilter.portfolioType:
        return config.portfolio.filters.portfolioType;
      case EPortfolioFilter.model:
        return config.portfolio.filters.model;
      case EPortfolioFilter.referenceCurrency:
        return config.portfolio.filters.referenceCurrency;
      case EPortfolioFilter.riskRange:
        return config.portfolio.filters.riskRange;
      case EPortfolioFilter.returnRange:
        return config.portfolio.filters.returnRange;
      case EPortfolioFilter.valueRange:
        return config.portfolio.filters.valueRange;
      case EPortfolioFilter.liquidityRange:
        return config.portfolio.filters.liquidityRange;
      case EPortfolioFilter.bu:
        return config.portfolio.filters.bu;
      case EPortfolioFilter.waiveOfAdvice:
        return config.portfolio.filters.waiveOfAdvice;
      case EPortfolioFilter.mifid:
        return config.portfolio.filters.mifid;
      case EPortfolioFilter.fidleg:
        return config.portfolio.filters.fidleg;
      case EPortfolioFilter.qualifiedInvestor:
        return config.portfolio.filters.qualifiedInvestor;
      case EPortfolioFilter.rmLocation:
        return config.portfolio.filters.rmLocation;
      case EPortfolioFilter.rmDepartment:
        return config.portfolio.filters.rmDepartment;
      case EPortfolioFilter.rmMarket:
        return config.portfolio.filters.rmMarket;
      case EPortfolioFilter.manager:
        return config.portfolio.filters.manager;
      case EPortfolioFilter.advisor:
        return config.portfolio.filters.advisor;
      case EPortfolioFilter.advisoryType:
        return config.portfolio.filters.advisoryType;
      case EPortfolioFilter.serviceCenter:
        return config.portfolio.filters.serviceCenter;
      case EPortfolioFilter.businessDivision:
        return config.portfolio.filters.businessDivision;
      case EPortfolioFilter.sustainabilityProfile:
        return config.portfolio.filters.sustainabilityProfile;
      case EPortfolioFilter.riskBreachOnly:
        return config.portfolio.filters.riskBreachOnly;
      case EPortfolioFilter.excludeRiskBreach:
        return config.portfolio.filters.excludeRiskBreach;
      case EPortfolioFilter.allowOptOut:
        return config.portfolio.filters.allowOptOut;
      default:
        return false;
    }
  }
}

@Pipe({ name: 'isFilterClientFilterActive' })
export class IsFilterClientFilterActivePipe implements PipeTransform {
  constructor(private readonly filterConfigService: UiFilterConfigService) {}
  transform(filter: EClientFilter): boolean {
    const config = this.filterConfigService.getConfigFlags();
    switch (filter) {
      case EClientFilter.ageRange:
        return config.client.filters.ageRange;
      case EClientFilter.domiciles:
        return config.client.filters.domiciles;
      case EClientFilter.gender:
        return config.client.filters.gender;
      default:
        return false;
    }
  }
}

@Pipe({ name: 'isFilterIntermediaryFilterActive' })
export class IsFilterIntermediaryFilterActivePipe implements PipeTransform {
  constructor(private readonly filterConfigService: UiFilterConfigService) {}
  transform(filter: EIntermediaryFilter): boolean {
    const config = this.filterConfigService.getConfigFlags();
    switch (filter) {
      case EIntermediaryFilter.excludeAll:
        return config.intermediaries.filters.excludeAll;
      case EIntermediaryFilter.excludeEAM:
        return config.intermediaries.filters.excludeEAM;
      case EIntermediaryFilter.excludeEWA:
        return config.intermediaries.filters.excludeEWA;
      default:
        return false;
    }
  }
}

@Pipe({ name: 'isFilterRangeWeightFilerActive' })
export class IsFilterRangeWeightFilerActivePipe implements PipeTransform {
  constructor(private readonly filterConfigService: UiFilterConfigService) {}
  transform(
    filter: ERangeWeightFilter,
    filterCategory: EFilterCategories
  ): boolean {
    const config = this.filterConfigService.getConfigFlags();
    switch (filter) {
      case ERangeWeightFilter.exposure:
        switch (filterCategory) {
          case EFilterCategories.assetClass:
            return config.assetClass.filters.exposure;
          case EFilterCategories.currency:
            return config.currency.filters.exposure;
          case EFilterCategories.region:
            return config.region.filters.exposure;
          case EFilterCategories.sector:
            return config.sector.filters.exposure;
          default:
            return false;
        }
      case ERangeWeightFilter.weight:
        switch (filterCategory) {
          case EFilterCategories.assetClass:
            return config.assetClass.filters.weight;
          case EFilterCategories.currency:
            return config.currency.filters.weight;
          case EFilterCategories.region:
            return config.region.filters.weight;
          case EFilterCategories.sector:
            return config.sector.filters.weight;
          default:
            return false;
        }
      default:
        return false;
    }
  }
}

@Pipe({ name: 'getFormattedValue' })
export class GetFormattedValuePipe implements PipeTransform {
  constructor(private globalService: GlobalService) {}
  transform(value: number, decimals: number): string {
    return this.globalService.getFormattedValue(value, decimals);
  }
}

@Pipe({ name: 'nestedMenuArrayFilter' })
export class NestedMenuArrayFilterPipe implements PipeTransform {
  transform(items: INestedMenuItem[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();
    return items.filter(
      (item) =>
        item.label.toLowerCase().startsWith(searchText.toLowerCase()) ||
        item.key.toLowerCase().startsWith(searchText.toLowerCase())
    );
  }
}

@Pipe({ name: 'hasChildren' })
export class HasChildrenPipe implements PipeTransform {
  transform(item: INestedMenuItem): boolean {
    return item.children && item.children.length > 0;
  }
}
