export enum EProtectedActions {
  addPortfolioCampaign = 'campaign-edit',
  addIntermediaryCampaign = 'campaign-edit',
  assignToAdvisorCampaign = 'action-execute',
  assignToRmCampaign = 'action-execute',
  autoSyncCampaign = 'campaign-autosync',
  autoSyncStory = 'story-autosync',
  closeCampaign = 'campaign-close',
  createCampaign = 'campaign-create-no-story',
  createCampaignOnStory = 'campaign-create-from-story',
  createStory = 'story-edit',
  decentralizedCampaignCreate = 'decentralized-campaign-create',
  decentralizedCampaignMonitoring = 'decentralized-campaign-monitoring',
  decentralizedCampaignEdit = 'decentralized-campaign-edit',
  decentralizedCampaignDelete = 'decentralized-campaign-delete',
  deleteCampaign = 'campaign-delete',
  deletePortfolioClientCampaign = 'action-delete',
  deleteStory = 'story-edit',
  distributor = 'distributor',
  editActionLanguage = 'action-edit',
  editBulk = 'action-edit',
  editCampaign = 'campaign-edit',
  editCampaignAccess = 'campaign-edit-access',
  editCampaignChannel = 'action-edit',
  editCampaignContents = 'campaign-edit-content',
  editCampaignCustomContent = 'campaign-edit-custom-content',
  editCampaignDetails = 'campaign-edit-details',
  editCampaignFilters = 'campaign-edit',
  editCampaignInfo = 'campaign-edit',
  editCampaignIntroOutro = 'campaign-edit',
  editCampaignProducts = 'campaign-edit-products',
  editCampaignSender = 'action-edit-sender',
  editClient = 'client-edit',
  editCollection = 'collection-edit',
  editEmployee = 'employee-edit',
  editPortfolio = 'portfolio-edit',
  editProduct = 'product-edit',
  editStory = 'story-edit',
  editStoryAccess = 'story-edit-access',
  editStoryContents = 'story-edit-content',
  editStoryDetails = 'story-edit-details',
  editStoryFilters = 'story-edit',
  editStoryInfo = 'story-edit',
  editStoryIntroOutro = 'story-edit',
  editStoryProducts = 'story-edit-products',
  executeActionCampaign = 'action-execute',
  freezeCampaign = 'campaign-freeze',
  isAdmin = 'administrator',
  launchCampaign = 'campaign-launch',
  processCampaign = 'campaign-retry',
  refreshSingleSuitabilityCampaign = 'action-edit',
  refreshSuitabilityCampaign = 'action-refresh-all-suitabilities',
  revertActionToPendingCampaign = 'action-execute',
  revertLaunchCampaign = 'campaign-unlaunch',
  revertToLaunchedCampaign = 'campaign-relaunch',
  selectOnCampaignScatterChart = 'campaign-edit',
  selectOnStoryScatterChart = 'story-edit',
  setNoActionCampaign = 'action-execute',
  sortAndFilterCid = 'cid-sort-filter',
  terminateCampaign = 'campaign-terminate',
  unfreezeCampaign = 'campaign-unfreeze',
  updateMonitoring = 'monitoring-update',
  viewCampaignAllowedUsers = 'campaign-view-access',
  viewCampaigns = 'campaign-view',
  viewCampaignsAll = 'campaign-view-all',
  viewClients = 'client-view',
  viewEmployees = 'employee-view',
  viewIntermediaries = 'intermediary-view',
  viewFiltering = 'campaign-view',
  viewMonitoring = 'monitoring-view',
  viewObjects = 'view-objects',
  viewPortfolios = 'portfolio-view',
  viewProducts = 'product-view',
  viewSettings = 'user-view',
  viewStories = 'story-view',
  viewStoryAllowedUsers = 'story-view-access',
}

