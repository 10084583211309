import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewChildren,} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ColumnState, GridApi} from 'ag-grid-community';
import {BehaviorSubject, Subscription} from 'rxjs';
import {
  Campaign,
  CampaignAction, CampaignActionSelection, CampaignActionService,
  CampaignActionStatus, CampaignIntermediaryAction, CampaignIntermediaryActionService,
  CollectionService,
  ContentMatchState,
  EmployeeCollectionService, ListParamsWithSelection,
  Portfolio,
} from 'src/app/api/core';
import {ModalData} from 'src/app/models/modal.model';
import {ModalService,} from 'src/app/services/modal.service';
import {NotificationService} from 'src/app/services/notification.service';
import {EModalType, EPortfolioActionStatus} from 'src/app/util/enum';
import {GridSelectionUtils} from 'src/app/util/grid/grid-selection.util';
import {GridSelectedItemsProvider, GridSelectedItemsProviderByCollection,} from '../../data-source';
import {DatasourceFilter} from '../../datasource-filter';
import {
  CampaignActionProcessingComponent
} from '../../../../campaign/views/campaign-overview/campaign-action-processing/campaign-action-processing.component';
import {PermissionService} from '../../../../services/permission.service';
import {EProtectedActions} from '../../../../util/protected-actions';
import {ButtonAction, DropdownSelectionBarComponent,} from '../dropdown-selection-bar/dropdown-selection-bar.component';
import {GridResetEvent} from '../../grid.component';
import {MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckbox, MatCheckboxDefaultOptions,} from '@angular/material/checkbox';
import {GridFilterModel} from "../../../../models/grid.model";
import {GlobalService} from "../../../../services/global.service";
import {ActionType} from "../../../../campaign/views/campaign-overview/campaign-actions-list-utils";
import {IsCampaignEditablePipe} from "../../../shared.pipe";
import {
  UserCollectionPickerComponent
} from "../../../../my-settings/dialogs/user-collection-picker/user-collection-picker.component";
import {
  EmployeeCollectionPickerComponent
} from "../../../../my-settings/dialogs/employee-collection-picker/employee-collection-picker.component";

interface PortfolioActionRow {
  action: CampaignAction;
  portfolio: Portfolio;
}

@Component({
  selector: 'app-table-selection-bar',
  templateUrl: './table-selection-bar.component.html',
  providers: [
    // We set the checkbox click action to no operation so that we can handle it manually
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions,
    },
  ],
})
export class TableSelectionBarComponent implements OnInit, OnDestroy {
  @ViewChildren('selectAllCheckbox')
  selectAllCheckbox: MatCheckbox;

  @ViewChild('selectionDropdown')
  selectionDropdown: DropdownSelectionBarComponent;

  @Input() isHierarchyList = false;
  @Input() gridApi: GridApi;
  @Input() gridSelectionUtils: GridSelectionUtils;
  @Input() level = 0;
  @Input() itemLabelRef?: string;
  @Input() labelRefs: {
    singular: string;
    plural: string;
  };
  @Input() selectAllProvider: GridSelectedItemsProvider;
  @Input() selectAllPreselectionProvider: GridSelectedItemsProvider;
  @Input()
  selectAllByCollectionPreselectionProvider: GridSelectedItemsProviderByCollection;
  @Input() deleteVisible: boolean;
  @Input() actionsVisible: boolean;
  @Input() campaign: Campaign;
  @Input() filterModelSubject: BehaviorSubject<GridFilterModel>;
  @Input() actionType = ActionType.CampaignAction;
  @Output() refreshTable = new EventEmitter();
  @Output() expandAll = new EventEmitter();
  @Output() collapseAll = new EventEmitter();
  @Output() selectionCleared = new EventEmitter<GridResetEvent>();
  // emits TRUE when selection has started, then FALSE when selection has ended processing
  @Output() selectionProcessing = new EventEmitter<boolean>();
  isEditableCampaign = false;
  someSelected = false;
  allSelected = false;

  selectedValues: PortfolioActionRow[] = [];

  private someActionsAreExecutable = false;
  private someActionsAreReversible = false;

  actions: ButtonAction[] = [
    {
      icon: 'delete',
      text: 'delete',
      className: 'icon-text-btn error-btn',
      click: () => this.delete(),
      tooltip: () => this.translateService.instant('deleteSelectionFromCampaign'),
      show: () =>
        this.deleteVisible &&
        this.hasDeletePermissions(),
    },
    {
      icon: 'send',
      text: 'send',
      className: 'icon-text-btn accent-btn',
      click: () =>
        this.someActionsAreExecutable && this.executeActions(),
      tooltip: () =>
        this.someActionsAreExecutable
          ? this.translateService.instant('send')
          : this.translateService.instant('sendNotPossible'),
      disabled: () => !this.someActionsAreExecutable,
      show: () =>
        this.actionsVisible &&
        this.permissionService.hasAnyPermission(
          EProtectedActions.executeActionCampaign
        ),
    },
    {
      icon: 'bulk_edit',
      text: 'bulkEdit',
      className: 'icon-text-btn',
      click: () => this.showBulkEditButton() && this.showBulkEdit(),
      disabled: () => this.disableBulkEditButton(),
      show: () =>
        this.actionsVisible && this.showBulkEditButton() && this.bulkEdit,
    },
  ];

  hiddenActions: ButtonAction[] = [];

  collectionIds: Set<number> = new Set<number>();
  automaticallyCreatedCollections: ButtonAction[] = [
    {
      text: 'preselectionRules',
      click: () => this.selectAllPagesPreselection(),
      show: () => !!this.selectAllPreselectionProvider,
      privateCollection: false,
    },
  ];
  createNewCollectionAction: ButtonAction = {
    text: 'collectionsAdd',
    className: 'create-collection-btn',
    click: () => this.createNewCollection(),
    show: () => true,
    privateCollection: false,
  };
  selectionHiddenActions: ButtonAction[] = this.actionType == ActionType.CampaignAction ?
    [...this.automaticallyCreatedCollections, this.createNewCollectionAction] : [this.createNewCollectionAction];

  bulkEdit = false;
  private subscriptions: Subscription[] = [];

  constructor(
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private modalService: ModalService,
    private permissionService: PermissionService,
    private collectionService: CollectionService,
    private employeeCollectionService: EmployeeCollectionService,
    private globalService: GlobalService,
    private readonly isCampaignEditablePipe: IsCampaignEditablePipe,
    private readonly campaignActionService: CampaignActionService,
    private readonly campaignIntermediaryActionService: CampaignIntermediaryActionService,
  ) {
    this.bulkEdit = this.permissionService.hasAnyPermission(
      EProtectedActions.editBulk
    );
  }

  ngOnInit() {
    this.selectedValues = this.gridSelectionUtils.getSelectedValues();
    this.subscriptions.push(
      this.gridSelectionUtils
        .getSelectionChangeObservable()
        .subscribe(() => {
          this.selectedValues = this.gridSelectionUtils.getSelectedValues();
          this.hiddenActions = this.generateHiddenActions();
          this.updateSelectionStatus();
        }),
      this.permissionService.user$.subscribe(() => {
        this.updateSelectionHiddenActions();
      })
    );
    this.isEditableCampaign = this.isCampaignEditablePipe.transform(this.campaign.status);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private generateHiddenActions(): ButtonAction[] {
    const isCampaignAction = this.actionType === ActionType.CampaignAction;
    return [
      {
        icon: 'cancel_schedule_send',
        text: 'noAction',
        className: 'icon-text-btn',
        click: () =>
          this.someActionsAreExecutable && this.noActions(),
        tooltip: () =>
          this.someActionsAreExecutable
            ? this.translateService.instant('setNoAction')
            : this.translateService.instant('setNoActionNotPossible'),
        disabled: () => !this.someActionsAreExecutable,
        show: () =>
          this.actionsVisible &&
          this.permissionService.hasAnyPermission(
            EProtectedActions.setNoActionCampaign
          ),
      },
      {
        icon: 'restart_alt',
        text: 'revertAction',
        className: 'icon-text-btn',
        click: () =>
          this.someActionsAreReversible && this.revertActions(),
        tooltip: () =>
          this.someActionsAreReversible
            ? this.translateService.instant('revertAction')
            : this.translateService.instant('revertActionNotPossible'),
        disabled: () => !this.someActionsAreReversible,
        show: () =>
          this.actionsVisible &&
          this.permissionService.hasAnyPermission(
            EProtectedActions.revertActionToPendingCampaign
          ),
      },
      isCampaignAction ? {
        icon: 'person_add',
        text: 'assignToAdvisor',
        className: 'icon-text-btn',
        click: () =>
          this.isAssignableForSomeSelectedElems('advisor') &&
          this.assignToAdvisor(),
        tooltip: () => this.translateService.instant('assignToAdvisor'),
        disabled: () => !this.isAssignableForSomeSelectedElems('advisor'),
        show: () =>
          this.actionsVisible &&
          this.permissionService.hasAnyPermission(
            EProtectedActions.assignToAdvisorCampaign
          ),
      } : undefined,
      isCampaignAction ? {
        icon: 'supervised_user_circle',
        text: 'assignToRelationshipManager',
        className: 'icon-text-btn',
        click: () =>
          this.isAssignableForSomeSelectedElems('relationshipManager') &&
          this.assignToRelationshipManager(),
        tooltip: () => this.translateService.instant('assignToRelationshipManager'),
        disabled: () =>
          !this.isAssignableForSomeSelectedElems('relationshipManager'),
        show: () =>
          this.actionsVisible &&
          this.permissionService.hasAnyPermission(
            EProtectedActions.assignToRmCampaign
          ),
      } : undefined,
      isCampaignAction ? {
        icon: 'sync',
        text: 'suitability',
        className: 'icon-text-btn',
        click: () => this.refreshSuitability(),
        tooltip: () => this.translateService.instant('updateSuitability'),
        show: () =>
          this.permissionService.hasAnyPermission(
            EProtectedActions.refreshSuitabilityCampaign
          ),
      } : undefined,
    ].filter(a => a);
  }

  getDetailRowsOfMasterRows(masterRows: any[]): any[] {
    const subRows = [];
    masterRows.forEach((n) => {
      if (n.actions) {
        n.actions.forEach((a) => subRows.push(a));
      }
    });
    return subRows;
  }

  /**
   * Toggles the selection of all rows, it checks the selection state and avoids to use the checkbox state
   * since it is not reliable when the selection is done by code.
   */
  togglePageSelection(_: MouseEvent) {
    this.selectionProcessing.emit(true);
    this.selectAllCheckbox.checked = (this._someSelected() || !this._allSelected());
    this.clearSelection();
    this.selectAllPages(this.selectAllCheckbox.checked);
  }

  private selectAllPages(selected: boolean = true) {
    const filter = new DatasourceFilter(this.gridApi, this.globalService);
    this.selectAllProvider(filter.toString()).subscribe((items) => {
      this.gridSelectionUtils.setMultipleSelection(items, selected);
      this.selectionProcessing.emit(false);
    });
  }

  selectAllPagesPreselection() {
    const filter = new DatasourceFilter(this.gridApi, this.globalService);
    this.selectionProcessing.emit(true);
    this.gridSelectionUtils.clearSelection();
    this.selectAllPreselectionProvider(filter.toString()).subscribe((items) => {
      this.gridSelectionUtils.setMultipleSelection(items, true);
      this.sortBySelected();
      this.gridApi.refreshServerSide({ purge: true }); // force server side refresh, to apply sorting
      this.selectionProcessing.emit(false);
    });
  }

  getTooltip() {
    if (this._allSelected()) {
      return this.translateService.instant('deselectAll');
    } else {
      return this.translateService.instant('selectAllPages');
    }
  }

  clearSelection() {
    this.gridSelectionUtils.clearSelection();
    this.selectionDropdown?.clear();
    this.selectionCleared.emit({
      api: this.gridApi,
    });
  }

  _allSelected(): boolean {
    // TODO: this only considers current page, not all pages https://github.com/confinale/aspark/issues/9100
    const rowsOnPage = this.getRowsOnPage();
    const nodesOnPage = this.getDetailRowsOfMasterRows(rowsOnPage).filter((k) =>
      this.gridSelectionUtils.isSelectable(k)
    );

    if (nodesOnPage.length == 0 && rowsOnPage.length > 0) {
      return rowsOnPage.every((k) =>
        this.gridSelectionUtils.isSelected(
          k[this.gridSelectionUtils.indexField()]
        )
      );
    } else {
      return (
        nodesOnPage.length > 0 &&
        nodesOnPage.every((k) =>
          this.gridSelectionUtils.isSelected(
            k[this.gridSelectionUtils.indexField()]
          )
        )
      );
    }
  }

  _someSelected(): boolean {
    return (
      this.gridSelectionUtils.getSelectedValues().length > 0 &&
      !this._allSelected()
    );
  }

  refresh(): void {
    this.clearSelection();
    this.refreshTable.emit(true);
  }

  private openModal(modalData: ModalData) {
    const selectedCampaignActions = this.gridSelectionUtils.getSelectedValues();
    const modalType = modalData.type;
    const data = {
      ...modalData,
      submitBtn: { label: '' },
      cancelBtn: { label: this.translateService.instant('cancel') },
    };
    const dialogRef = this.modalService.openDefaultDialog(
      data,
      undefined,
      undefined,
      true
    );
    dialogRef.afterClosed().subscribe((clearSelection) => {
      // clear selection if modal was closed with submit, keeping the selection afterward if it's a bulk-edit
      if (clearSelection) {
        this.refresh();
        const keepSelectionTypes = [
          EModalType.bulkEdit
        ];

        if (keepSelectionTypes.includes(modalType)) {
          // a timeout is required since the refresh operation is asynchronous
          setTimeout(
            () => this.gridSelectionUtils.setMultipleSelection(selectedCampaignActions, true),
            250
          );
        }
      }
    });
  }

  refreshSuitability(): void {
    const selectedRows = this.getSelectedActionIdsRows();
    if (!selectedRows.length) {
      this.notificationService.handleWarning(
        this.translateService.instant('noMatchingActions')
      );
      return;
    }
    this.prepareDataAndShowModal(EModalType.refreshSuitabilitiesDialog, this.campaign, selectedRows);
  }

  delete(): void {
    const selectedRows = this.getSelectedActionIdsRows();
    if (!selectedRows.length) {
      this.notificationService.handleWarning(
        this.translateService.instant('noMatchingActions')
      );
      return;
    }
    this.prepareDataAndShowModal(EModalType.removeActionsDialog, this.campaign, selectedRows);
  }

  executeActions(): void {
    const selectedRows = this.getSelectedActionIdsRows(
      (row) => row.action.status === EPortfolioActionStatus.pending && row.action.contentMatchState !== ContentMatchState.MISSING
    );
    if (!selectedRows.length) {
      this.notificationService.handleWarning(
        this.translateService.instant('noMatchingActions')
      );
      return;
    }
    this.prepareDataAndShowModal(EModalType.executeActionsDialog, this.campaign, selectedRows);
  }

  noActions(): void {
    const selectedRows = this.getSelectedActionIdsRows(
      (row) => row.action.status === EPortfolioActionStatus.pending
    );
    if (!selectedRows.length) {
      this.notificationService.handleWarning(
        this.translateService.instant('noMatchingActions')
      );
      return;
    }
    this.prepareDataAndShowModal(EModalType.setNoActionsDialog, this.campaign, selectedRows);
  }

  revertActions(): void {
    const selectedRows = this.getSelectedActionIdsRows(
      (row) => row.action.status !== EPortfolioActionStatus.pending
    );
    if (!selectedRows.length) {
      this.notificationService.handleWarning(
        this.translateService.instant('noMatchingActions')
      );
      return;
    }
    this.prepareDataAndShowModal(EModalType.revertActionsDialog, this.campaign, selectedRows);
  }

  assignToAdvisor(): void {
    const selectedRows = this.getSelectedActionIdsRows(
      (row) =>
        row.action.status === EPortfolioActionStatus.pending &&
        row.portfolio.advisor &&
        row.portfolio.advisor.id !== row.action?.assignee?.id
    );
    if (!selectedRows.length) {
      this.notificationService.handleWarning(
        this.translateService.instant('noMatchingActions')
      );
      return;
    }
    this.prepareDataAndShowModal(EModalType.assignActionsToAdvisorDialog, this.campaign, selectedRows);
  }

  private prepareDataAndShowModal(modalType: EModalType, campaign: Campaign, selectedRows: PortfolioActionRow[]) {
    // we need to fetch the data before loading
    const params = { firstResult: 0, pageSize: selectedRows.length, selectedItems: selectedRows.map(d => d['id']) };
    const showModal = (rows) => {
      const modalData: ModalData = {
        title: null,
        type: modalType,
        data: { modalType, campaign, selectedRows: rows },
        component: CampaignActionProcessingComponent,
      };
      this.openModal(modalData);
    }
    const updateDataById = (existingData: any[], incomingData: any[]) => {
      return existingData.map((r) => {
        const updated = incomingData.find((d) => d.id === r['id']);
        return updated ? { ...r, action: updated } : undefined;
      }).filter(d => d);
    }
    this.gridApi.showLoadingOverlay();
    if (this.actionType === ActionType.CampaignAction) {
      this.campaignActionService.getCampaignActionsSelectionByListParams(this.campaign.id, params)
        .subscribe({
          next: (data: CampaignActionSelection[]) => showModal(updateDataById(selectedRows, data)),
          complete: () => this.gridApi.hideOverlay(),
        })
    } else {
      this.campaignIntermediaryActionService.getCampaignIntermediaryActions(params)
        .subscribe({
          next: (data: CampaignIntermediaryAction[]) => showModal(updateDataById(selectedRows, data)),
          complete: () => this.gridApi.hideOverlay(),
        });
    }
  }

  assignToRelationshipManager(): void {
    const selectedRows = this.getSelectedActionIdsRows(
      (row) =>
        row.action.status === EPortfolioActionStatus.pending &&
        row.portfolio.relationshipManager &&
        row.portfolio.relationshipManager?.id !== row.action.assignee?.id
    );
    if (!selectedRows.length) {
      this.notificationService.handleWarning(
        this.translateService.instant('noMatchingActions')
      );
      return;
    }
    this.prepareDataAndShowModal(EModalType.assignActionsToRelationshipManagerDialog, this.campaign, selectedRows);
  }

  actionIsExecutableForSomeSelectedElems(): boolean {
    const selValues = this.gridSelectionUtils.getSelectedValues();
    return (
      this.permissionService.hasAnyPermission(
        EProtectedActions.executeActionCampaign
      ) &&
      selValues.some(
        (d) => d.status === EPortfolioActionStatus.pending && d.action.contentMatchState !== ContentMatchState.MISSING // Hide if no action is pending
      )
    );
  }

  actionIsReversibleForSomeSelectedElems(): boolean {
    const selValues = this.gridSelectionUtils.getSelectedValues();

    return (
      this.permissionService.hasAnyPermission(
        EProtectedActions.executeActionCampaign
      ) &&
      !selValues.every(
        (action) => action.status === EPortfolioActionStatus.pending // Hide if all actions are pending
      )
    );
  }

  isAssignableForSomeSelectedElems(type: string): boolean {
    const assignableValues = this.getAssignableActions(type);
    return assignableValues.length > 0;
  }

  getAssignableActions(type: string): number[] {
    const filter =
      type === 'advisor'
        ? (row) => {
          return row.action.status === EPortfolioActionStatus.pending &&
            (!row.portfolio ||
              (row.portfolio.advisor && row.portfolio.advisor.id !== row.action.assignee?.id)
            )
        }
        : (row) => {
            return row.action.status === EPortfolioActionStatus.pending &&
              (!row.portfolio ||
                (row.portfolio.relationshipManager &&
                row.portfolio.relationshipManager.id !== row.action.assignee?.id)
              );
        }
    return this.getSelectedActionIdsRows(filter).map((row) => row.action.id);
  }

  private getRowsOnPage(): Array<any> {
    const nodes = new Array<any>();
    if (this.gridApi) {
      this.gridApi.forEachNode((rowNode, index) => {
        if (
          rowNode.data &&
          this.gridSelectionUtils.isRowInCurrentPage(
            rowNode,
            this.gridApi,
            index
          )
        ) {
          nodes.push(rowNode.data);
        }
      });
    }
    return nodes;
  }

  showBulkEditButton(): boolean {
    const selValues = this.gridSelectionUtils.getSelectedValues()
      .filter((cp: any) => !cp.action.hasContentOverride);
    return (
      this.permissionService.hasAllPermissions(
        EProtectedActions.editActionLanguage,
        EProtectedActions.editCampaignChannel,
        EProtectedActions.editCampaignSender
      ) && selValues.length > 0
    );
  }

  disableBulkEditButton(): boolean {
    const selValues = this.gridSelectionUtils.getSelectedValues()
      .filter((cp: any) => !cp.action.hasContentOverride);
    return !selValues.some(
      (action) => action.status === EPortfolioActionStatus.pending
    );
  }

  showBulkEdit() {
    const selectedRows = this.getSelectedActionIdsRows(
      (row) => row.action.status === CampaignActionStatus.PENDING
      && !row.action.hasContentOverride
    );
    if (!selectedRows.length) {
      this.notificationService.handleWarning(
        this.translateService.instant('noMatchingActions')
      );
      return;
    }
    this.prepareDataAndShowModal(EModalType.bulkEdit, this.campaign, selectedRows);
  }

  private getSelectedActionIdsRows(
    predicate: (row: PortfolioActionRow) => boolean = () => true
  ): PortfolioActionRow[] {
    const selectedActions = this.gridSelectionUtils.getSelectedValues();
    return selectedActions.filter(predicate);
  }

  private updateSelectionHiddenActions(): Promise<void> {
    return new Promise((resolve) => {
      (
        this.actionType == ActionType.CampaignAction
          ? this.collectionService.getUserAndDeputyCollections()
          : this.employeeCollectionService.getUserAndDeputyCollections()
      ).subscribe((collections) => {
        const newCollections = collections.filter((c) =>
          !this.collectionIds.has(c.id)
        );
        this.collectionIds = new Set<number>(collections.map((c) => c.id));
        const newActions = newCollections.map(
          (c) =>
            ({
              text: c.isOwner ? c.name : `${c.name} (${c.user.fullname})`,
              click: () => this.selectCollection(c.id),
              disabled: () => false,
              show: () => true,
              collectionId: c.id,
              privateCollection: c.isOwner,
            } as ButtonAction)
        );
        this.selectionHiddenActions.push(...newActions);
        this.selectionHiddenActions = this.selectionHiddenActions.filter((action) =>
          this.collectionIds.has(action.collectionId) || action.collectionId === undefined
        );
        // asynchronous operations need selectionHiddenActions to be updated before resolving
        resolve();
        // clear selection if previously selected collection is not in list anymore
        if (
          this.selectionDropdown &&
          this.selectionDropdown._selectedAction?.collectionId &&
          !this.collectionIds.has(this.selectionDropdown._selectedAction.collectionId)
        ) {
          this.clearSelection();
        }
      });
    });
  }

  private selectCollection(collectionId: number) {
    const filter = new DatasourceFilter(this.gridApi, this.globalService);
    this.selectionProcessing.emit(true);
    this.gridSelectionUtils.clearSelection();
    this.selectAllByCollectionPreselectionProvider(collectionId, filter.toString())
      .subscribe((items) => {
        this.gridSelectionUtils.setMultipleSelection(items, true);
        this.sortBySelected();
        this.gridApi.refreshServerSide({ purge: true }); // force server side refresh, to apply sorting
        this.selectionProcessing.emit(false);
      });
  }

  private sortBySelected() {
    const colStates: ColumnState[] = this.gridApi.getColumnState()
      .map(colState => ({
        ...colState,
        sort: colState.colId === 'id' ? 'desc' : null
      }));
    this.gridApi.applyColumnState({
      state: colStates
    });
  }

  protected readonly ActionType = ActionType;

  private hasDeletePermissions() {
    if (this.campaign.decentralized) {
      return this.permissionService.hasAnyPermission(
        EProtectedActions.decentralizedCampaignEdit
      )
    } else {
      return this.permissionService.hasAnyPermission(
        EProtectedActions.deletePortfolioClientCampaign
      )
    }
  }

  /**
   * Updates the selection status checkbox values based on the current selection status, should be called
   * only when needed.
   */
  private updateSelectionStatus() {
    this.allSelected = this._allSelected();
    this.someSelected = this._someSelected();
    this.someActionsAreExecutable = this.actionIsExecutableForSomeSelectedElems();
    this.someActionsAreReversible = this.actionIsReversibleForSomeSelectedElems();
  }

  private createNewCollection() {
    const modalData: ModalData = {
      type: EModalType.pickClients,
      title: 'collectionsAdd',
      data: {
        name: '',
        isPublic: false,
        selected: [],
      },
      component: this.actionType == ActionType.CampaignAction ?
        UserCollectionPickerComponent : EmployeeCollectionPickerComponent,
    };
    const dialogRef = this.modalService.openDefaultDialog(
      modalData,
      'pick-client-dialog'
    );
    dialogRef.afterClosed().subscribe((result: number[]) => {
      if (result) {
        this.updateSelectionHiddenActions().then(() => {
          this.selectionDropdown.selectedAction = this.selectionHiddenActions.find((action) =>
            action.collectionId && action.collectionId == result[0]
          );
        });
      }
    });
  }
}
