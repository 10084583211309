import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, ActivatedRouteSnapshot, ActivationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie-service';
import {combineLatest, first, Subscription} from 'rxjs';
import {
  AppMessageService,
  AppNextMessage,
  ClientService,
  CodeTableEntry,
  DynamicUser,
  FilterService,
  Gui,
  GuiService,
  Info,
  InfoService,
  UserRoleData,
  UserService,
} from 'src/app/api/core';
import {ModalData} from 'src/app/models/modal.model';
import {NavigationElement} from 'src/app/models/sidenav_config.model';
import {GlobalService} from 'src/app/services/global.service';
import {ModalService} from 'src/app/services/modal.service';
import {PermissionService} from 'src/app/services/permission.service';
import {EModalType, EViewRoutes, EViewTitles} from 'src/app/util/enum';
import {SIDENAV_CONFIG} from 'src/assets/config/sidenav_config';
import {EProtectedActions} from '../../util/protected-actions';
import {HelpComponent} from '../help/help.component';
import {ModalComponent} from '../modal/modal.component';
import {NotificationService} from '../../services/notification.service';
import {MatSelectChange} from '@angular/material/select';
import {DataService} from "../../services/data.service";
import {UiFilterConfigService} from "../../services/ui-filter-config.service";
import {
  DistributorClientOverviewComponent
} from "../../distributor/views/distributor-details-view/distributor-client-overview/distributor-client-overview.component";
import {
  showMessageViewDialog
} from "../../core/views/settings/app-message-view-dialog/app-message-view-dialog.component";
import {
  showMessageListDialog
} from "../../core/views/settings/app-message-list-dialog/app-message-list-dialog.component";

/**
 * Page Component.
 * Component for common page structure
 */
@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
})
export class PageComponent implements OnInit, OnDestroy {
  /**
   * Page title and title extension (e.g. current client person number)
   */
  title: string;
  titleExtension: string | undefined = undefined;
  /**
   * Side navigation elements
   */
  sidenavConfig: NavigationElement[];

  hasAnyRole: boolean;
  /**
   * Current route
   */
  currentViewRoute: EViewRoutes;
  /**
   * Parent route
   */
  activeParentRoute: EViewRoutes;
  /**
   * Initial language at page load
   */
  initialLang: string;
  /**
   * Currently selected language
   */
  currentLang: string;
  /**
   * The current instance info
   */
  info: Info;
  /**
   * Subscriptipons
   */
  subscriptions: Subscription[] = [];
  /**
   * Username of current user
   */
  currentUser: UserRoleData;

  dynamicUsers: DynamicUser[] = [];
  /**
   * Favorite currencies of current user
   */
  favoriteCurrencies: CodeTableEntry[] = [];
  /*
   * Show Admin Menu
   */
  adminMenu = false;

  guiConfig: Gui = {};

  nextMessage: AppNextMessage = { next: undefined, unread: 0};

  get viewRoutes() {
    return EViewRoutes;
  }

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
    private cookieService: CookieService,
    public translateService: TranslateService,
    private modalService: ModalService,
    private infoService: InfoService,
    private notificationService: NotificationService,
    private userService: UserService,
    private permissionService: PermissionService,
    private guiService: GuiService,
    private dataService: DataService,
    private clientService: ClientService,
    private filterService: FilterService,
    private filterConfigService: UiFilterConfigService,
    private appMessageService: AppMessageService,
  ) {
    this.loadAppInfo();
    this.subscriptions.push(
      this.router.events.subscribe((event) => {
        // on last navigation get route title to set to page
        if (event instanceof ActivationEnd) {
          let tmpRoute = this.route.snapshot;
          while (tmpRoute.firstChild) {
            tmpRoute = tmpRoute.firstChild;
          }
          this.title = tmpRoute.data.title;
          this.titleExtension = this.getHeaderTitleExtension(tmpRoute);
          this.currentViewRoute = tmpRoute.data.viewRoute;
          this.initialLang = this.translateService.currentLang;
          this.currentLang = this.translateService.currentLang;
          this.activeParentRoute = SIDENAV_CONFIG.find((s: NavigationElement) =>
            s.activeForRoutes.find(
              (r: EViewRoutes) => this.currentViewRoute === r
            )
          )?.route;
        }
      })
    );
    this.subscriptions.push(
      this.translateService.onLangChange.subscribe(
        (event) => (this.currentLang = event.lang)
      ),
      this.permissionService.user$.subscribe((user) => {
        this.currentUser = user;
        this.dynamicUsers = user.dynamicUsers.map((u) => ({ ...u }))
          .sort((a, b) => {
            return a.fullname.localeCompare(b.fullname)
          });
      }),
    );
    this.permissionService
      .updateAndGetPermissions()
      .pipe(first())
      .subscribe((roles) => {
        this.hasAnyRole = roles && roles.length > 0;
        this.sidenavConfig = SIDENAV_CONFIG.map((t) => {
          if (t.title !== EViewTitles.home) {
            t.active = this.hasAnyRole;
          }
          return t;
        });
        if (this.hasAnyRole) {
          this.loadAuthorizedData();
        }
      });
      this.loadMessages();
  }

  private loadMessages() {
    // listen to auth-events to perform actions after token is received
    this.subscriptions.push(
      this.permissionService.logged$.subscribe((logged) => {
        if (logged) {
          this.appMessageService.getNextMessage()
            .subscribe(next => {
              this.nextMessage = next;
              this.showNextMessage();
            });
        }
      })
    );
  }

  private loadAppInfo() {
    this.info = JSON.parse(sessionStorage.getItem('appInfo'));
    if (!this.info) {
      // this is called once per session
      const calls = [
        this.infoService.getInfo(),
        this.permissionService.hasAnyPermission(EProtectedActions.distributor)
          ? this.clientService.updateDistributorClientPortfolios()
          : undefined,
      ].filter(d => d);
      combineLatest(calls)
        .subscribe(([info]) => {
          this.info = info as Info;
          this.titleService.setTitle(this.info.title);
          sessionStorage.setItem('appInfo', JSON.stringify(info));
        });
    } else {
      this.titleService.setTitle(this.info.title);
    }
  }

  private loadAuthorizedData() {
    combineLatest([
      this.guiService.getConfig(),
      this.filterService.getUserFilterConfigs(),
    ]).subscribe(([gui, userFilterConfigs]) => {
      // initialize components that behave differently with some feature flags enabled
      this.adminMenu = this.permissionService.hasAnyPermission(
        EProtectedActions.isAdmin
      );
      // gui configs
      this.guiConfig = gui;
      // filter configs
      this.filterConfigService.updateConfigFlags(userFilterConfigs);
      this.loadCurrencies();
    });
  }

  /**
   * Loads favorite currencies of current user
   * @private
   */
  private loadCurrencies() {
    this.subscriptions.push(
      this.dataService.favoriteCurrencies$.subscribe((currencies) => {
        if (currencies.length === 0) {
          this.userService.getFavoriteCurrencies().subscribe((currencies) => {
            if (currencies.length === 0) {
              this.userService.updateFavoriteCurrencies(currencies).subscribe((currencies) => {
                this.dataService.updateFavoriteCurrencies(currencies);
              });
            } else {
              this.dataService.updateFavoriteCurrencies(currencies);
            }
          });
        }
        this.favoriteCurrencies = currencies.filter(c => c.id !== this.currentUser.selectedCurrency.id);
      })
    );
  }

  private getHeaderTitleExtension(snapshot: ActivatedRouteSnapshot): string | undefined {
    // TODO: We implement this currently only for Distributor - Client Overview https://github.com/confinale/aspark/issues/9024
    switch (snapshot.component) {
      case DistributorClientOverviewComponent:
        return snapshot.data.client.personNumber;
      default:
        return undefined;
    }
  }

  ngOnInit(): void { }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Navigates to provided path
   * @param route Path to navigate to
   */
  openPath(route: EViewRoutes): void {
    if (route) {
      this.globalService.navigate(route);
    }
  }

  /**
   * Signs out currently signed-in user
   */
  signOut(): void {
    this.globalService.logout();
  }

  /**
   * Triggered by lang switcher in HTML.
   * Sets selected language as current lang and saves it as a cookie
   * @param event MatSelectChange
   */
  setLanguage(event: MatSelectChange): void {
    const modalData: ModalData = {
      title: this.translateService.instant('switchLanguage?'),
      type: EModalType.confirmationDialog,
      data: {
        message: this.translateService.instant('switchLanguageMessage'),
      },
      component: null,
      submitBtn: {
        label: this.translateService.instant('switchLanguage'),
        callback: (modalRef: MatDialogRef<ModalComponent>) => {
          modalRef.close(true);
          this.cookieService.set('selectedLang', event.value, null, '/');
          window.location.reload();
        },
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
        callback: (modalRef: MatDialogRef<ModalComponent>) => {
          this.currentLang = this.initialLang;
          modalRef.close(false);
        },
      },
    };

    this.modalService.openConfirmationDialog(modalData);
  }

  onHelpClick(): void {
    if (this.guiConfig.helpUrlNewTab && this.guiConfig.helpUrl?.length > 0) {
      // Will always open in a new tab, even if there is already a help tab open.
      // Otherwise, we would need to know the tab name to switch to it
      // (not guaranteed, as browser settings could be set to always open link in new tab)
      window.open(this.guiConfig.helpUrl, '_blank');
    } else {
      const modalData = new ModalData(
        EModalType.help,
        {
          route: this.currentViewRoute,
          title: this.title,
          helpUrl: this.guiConfig.helpUrl,
        },
        'help',
        HelpComponent
      );
      this.modalService.openDefaultDialog(modalData, 'help-dialog');
    }
  }

  onErrorClick(): void {
    this.globalService.navigate(EViewRoutes.errors);
  }

  onSettingsClick(): void {
    this.openPath(EViewRoutes.settings);
  }

  onHomeClick(): void {
    this.openPath(EViewRoutes.home);
  }

  /**
   * Updates the dynamic users only when the user menu is closed and some changes have been detected.
   */
  updateDynamicUsers() {
    const isChanged =
      this.dynamicUsers.filter((u) => u.enabled).length !==
      this.currentUser.dynamicUsers.filter((u) => u.enabled).length;
    if (isChanged) {
      this.userService
        .updateDynamicUsers(this.dynamicUsers)
        .subscribe((updatedUsers) => {
          this.notificationService.handleSuccess(
            this.translateService.instant('bulkEditSuccess')
          );
          const user = {
            ...this.currentUser,
            dynamicUsers: updatedUsers,
          };
          this.permissionService.updateUser(user);
        });
    }
  }

  updateSelectedCurrency(c: CodeTableEntry) {
    const modalData: ModalData = {
      title: this.translateService.instant('switchCurrency?'),
      type: EModalType.confirmationDialog,
      data: {
        message: this.translateService.instant('switchCurrencyMessage'),
      },
      component: null,
      submitBtn: {
        label: this.translateService.instant('switchCurrency'),
        callback: (modalRef: MatDialogRef<ModalComponent>) => {
          modalRef.close(true);
          this.userService.updateSelectedCurrency(c).subscribe(() => {
            window.location.reload();
          });
        },
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
        callback: (modalRef: MatDialogRef<ModalComponent>) => {
          modalRef.close(false);
        },
      },
    };

    this.modalService.openConfirmationDialog(modalData);
  }

  showOldMessages() {
    showMessageListDialog(
      this.translateService,
      this.modalService,
    );
  }

  showNextMessage() {
    if (!this.nextMessage.next) return;
    showMessageViewDialog(
      this.nextMessage.next,
      this.translateService,
      this.modalService,
      () => {
      this.appMessageService.readMessage(this.nextMessage.next.id)
        .subscribe(next => {
          this.nextMessage = next;
          this.showNextMessage();
        });
    });
  }
}
