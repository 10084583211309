import {HttpClient} from '@angular/common/http';
import {TranslateLoader} from '@ngx-translate/core';
import {map} from 'rxjs/operators';
import {forkJoin} from 'rxjs';

export function translateLoader(http: HttpClient) {

  return new MultiTranslateHttpLoader(http, [
    {prefix: './assets/i18n/', suffix: '.json'},
    {prefix: './assets/i18n/gen/campaign-processing-progress-', suffix: '.json'},
  ]);
}

export class MultiTranslateHttpLoader implements TranslateLoader {

  constructor(private http: HttpClient,
              public resources: { prefix: string; suffix: string }[] = [{
                prefix: '/assets/i18n/',
                suffix: '.json'
              }]) {
  }

  /**
   * Gets the translations from the server
   * @param lang Language code
   * @returns Translation Dictionary
   */
  public getTranslation(lang: string): any {
    return forkJoin(this.resources.map(config =>
      this.http.get(`${config.prefix}${lang}${config.suffix}`)
    )).pipe(
      map(response => response.reduce((a, b) => Object.assign(a, b)))
    );
  }
}
