<div class="portfolio-details-container aspark-container-padding" *ngIf="portfolio">
  <div class="detail-items-wrapper">
    <div class="start-col">
      <app-overview-detail-item
        labelRef="number"
        [value]="portfolio.number"
      ></app-overview-detail-item>
      <app-overview-detail-item
        labelRef="portfolioType"
        [value]="portfolio.portfolioType?.name"
      ></app-overview-detail-item>
      <app-overview-detail-item
        labelRef="advisoryType"
        [value]="portfolio.advisoryType?.name"
      ></app-overview-detail-item>
      <app-overview-detail-item
        labelRef="strategy"
        [value]="portfolio.strategy?.name"
      ></app-overview-detail-item>
    </div>
    <div class="inside-col">
      <app-overview-detail-item
        labelRef="currency"
        [value]="portfolio.referenceCurrency.ident"
      ></app-overview-detail-item>
      <app-overview-detail-item
        [label]="labelBuilder.labelWithCurrency('portfolioValue')"
        [value]="portfolio.portfolioValue | toDecimal: 2"
      ></app-overview-detail-item>
      <app-overview-detail-item
        labelRef="risk"
        [value]="portfolio.risk | formatPercentage: true"
      ></app-overview-detail-item>
      <app-overview-detail-item
        labelRef="riskSpreadMin"
        [value]="portfolio.riskSpreadMin | formatPercentage: true"
      ></app-overview-detail-item>
    </div>
    <div class="inside-col">
      <app-overview-detail-item
        labelRef="riskSpreadMax"
        [value]="portfolio.riskSpreadMax | formatPercentage: true"
      ></app-overview-detail-item>
      <app-overview-detail-item
        labelRef="bpName"
        [value]="portfolio.bpName"
      ></app-overview-detail-item>
      <app-overview-detail-item
        labelRef="bpNumber"
        [value]="portfolio.bpNumber"
      ></app-overview-detail-item>
      <app-overview-detail-item
        labelRef="preferredLanguage"
        [value]="portfolio.preferredLanguage.name"
      ></app-overview-detail-item>
    </div>
    <div class="end-col">
      <app-overview-detail-item
        labelRef="relationshipManager"
        [value]="usernameLabel(portfolio.relationshipManager)"
      ></app-overview-detail-item>
      <app-overview-detail-item
        labelRef="advisor"
        [value]="usernameLabel(portfolio.advisor)"
      ></app-overview-detail-item>
    </div>
  </div>

  <div class="positions">
    <app-grid
      tableId="campaign-portfolio-positions-grid"
      [rowData]="data"
      [columnDefs]="columnDefs"
      [autoGroupColumnDef]="autoGroupColumnDef"
      [gridOptions]="gridOptions"
      [pagination]="false"
    ></app-grid>
  </div>
</div>
