import {Component, Inject, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {
  GridFilterOptionsParams,
  ListParams,
  Product,
  ProductListItem,
  ProductPaginated,
  ProductService
} from "../../../api/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ModalComponent} from "../../../shared/modal/modal.component";
import {ColDef, GridApi, GridOptions, GridReadyEvent, ModelUpdatedEvent, RowSelectedEvent} from "ag-grid-community";
import {genCodeTableColumn, genTextColumnWithAutoCompleteFilter} from "../../../util/grid/grid-renderer.util";
import {TranslateService} from "@ngx-translate/core";
import {CodeTableService} from "../../../services/code-table.service";
import {GridDataProvider} from "../../../shared/grid/data-source";
import {ECodeTables, EModalType} from "../../../util/enum";
import {ModalData, ModalSubComponent} from "../../../models/modal.model";
import {GridComponent} from "../../../shared/grid/grid.component";
import {fromEvent, Observable, Subscription} from "rxjs";
import {DialogHeight, DialogWidth, ModalService} from "../../../services/modal.service";
import {debounceTime, distinctUntilChanged, map} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {MatCheckbox} from "@angular/material/checkbox";

function columnTooltip(colDef: ColDef, cb: (data: ProductListItem) => string, customPath?: string): ColDef {
  // Uncomment this line if you want to add tooltips to the cells
  // colDef.tooltipValueGetter = (params) => cb(params.data)
  if (customPath) {
    colDef.filterParams.customPath = customPath
  }
  return colDef;
}

@Component({
  selector: 'app-product-search-dialog',
  templateUrl: './product-search-dialog.component.html'
})
export class ProductSearchDialogComponent implements ModalSubComponent, OnInit, OnDestroy {
  @ViewChild("product_grid")
  grid: GridComponent;
  @ViewChild("selectAllCheckbox")
  selectAllCheckbox: MatCheckbox;

  search: string = '';
  lastList: ListParams;

  /**
   * List of all assets in the selected assets grid table
   */
  selectedProducts: Product[] = [];
  /**
   * Column definition for the "all product" grid
   */
  columnDefs: ColDef[] = [
    {
      checkboxSelection: true,
      floatingFilter: false,
      sortable: true,
      sort: "desc",
      lockPosition: 'left',
      suppressHeaderMenuButton: true,
      lockVisible: true,
      suppressColumnsToolPanel: true,
    },
    {
      ...genTextColumnWithAutoCompleteFilter({
        field: 'isin',
        headerName: this.translateService.instant('isin'),
        autoCompleteParams: {
          apiMethod: (data: GridFilterOptionsParams) => this.productService.getGridFilterOptions(data),
          autoCompleteField: 'isin',
          autoCompleteContextId: undefined,
        },
      }),
      floatingFilter: true,
      sortable: true,
    },
    {
      ...genTextColumnWithAutoCompleteFilter({
        field: 'name',
        headerName: this.translateService.instant('productName'),
        autoCompleteParams: {
          apiMethod: (data: GridFilterOptionsParams) => this.productService.getGridFilterOptions(data),
          autoCompleteField: 'name',
          autoCompleteContextId: undefined,
        },
      }),
      floatingFilter: true,
      sortable: true,
    },
    columnTooltip(
     {
      ...genTextColumnWithAutoCompleteFilter({
        field: 'country.name',
        headerName: this.translateService.instant('country'),
        autoCompleteParams: {
          apiMethod: (data: GridFilterOptionsParams) => this.productService.getGridFilterOptions(data),
          autoCompleteField: 'country',
          autoCompleteContextId: undefined,
        },
      }),
      floatingFilter: true,
      sortable: true,
    },
        p => p.country.ident,
      "countryName"
    ),
    columnTooltip(
      {
        ...genTextColumnWithAutoCompleteFilter({
          field: 'currency.name',
          headerName: this.translateService.instant('referenceCurrency'),
          autoCompleteParams: {
            apiMethod: (data: GridFilterOptionsParams) => this.productService.getGridFilterOptions(data),
            autoCompleteField: 'currency',
            autoCompleteContextId: undefined,
          },
        }),
        floatingFilter: true,
        sortable: true,
      },
      p => p.currency.ident,
      "currencyName"
    ),
    columnTooltip(
      genCodeTableColumn({
        field: 'assetClass',
        headerName: this.translateService.instant('assetClass'),
        observable: this.codeTableService.getCodeTable(ECodeTables.assetClass),
      }),
      p => p.assetClass.ident,
      "assetClassId"
    ),
    columnTooltip(
      genCodeTableColumn({
        field: 'type',
        headerName: this.translateService.instant('assetType'),
        observable: this.codeTableService.getCodeTable(ECodeTables.assetType),
      }),
      p => p.type.ident,
      "typeId"
    ),
    columnTooltip(
      genCodeTableColumn({
        field: 'rating',
        headerName: this.translateService.instant('rating'),
        observable: this.codeTableService.getCodeTable(ECodeTables.rating),
      }),
      p => p.rating?.ident,
      "ratingId"
    ),
  ];
  /**
   * Grid options
   */
  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    suppressRowClickSelection: true,
    rowMultiSelectWithClick: true,
    paginationAutoPageSize: true,
    rowSelection: 'multiple',
    rowModelType: "serverSide",
    getRowId: (params) => params.data.id,
    onGridReady: (event: GridReadyEvent) => this.gridReady(event),
    onRowSelected: (event: RowSelectedEvent) => this.rowSelected(event),
    onModelUpdated: (event: ModelUpdatedEvent) => this.modelUpdated(event),
  };
  /**
   * Grid API
   */
  gridApi: GridApi;
  /**
   * Data provider for grid "Available products"
   */
  gridData: GridDataProvider;
  init = false;
  subscriptions: Subscription[] = [];
  maxSelectAll: number = 100;
  selectAllOverflowMessage: string = "";

  constructor(
    readonly translateService: TranslateService,
    readonly codeTableService: CodeTableService,
    readonly productService: ProductService,
    readonly toastService: ToastrService,
    protected dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {data: {maxSelected: number}}
  ) {
    this.selectAllOverflowMessage = translateService.instant('selectAllOverflowMessage');
    this.maxSelectAll = data.data.maxSelected;
  }

  modalAction(modalType: EModalType) {
    this.dialogRef.close({selected: this.selectedProducts});
  }

  ngOnInit() {
    this.gridData = this.gridProvider.bind(this);
    const searchInput = document.getElementById('search-input') as HTMLInputElement;
    fromEvent(searchInput, 'input')
      .pipe(
        map((event: Event) => (event.target as HTMLInputElement).value),
        debounceTime(500),
        distinctUntilChanged(),
      )
      .subscribe(value => {
        this.search = value;
        this.grid.refresh(true);
      })
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  get allSelected() {
    const size = this.selectedProducts.length;
    return size >= this.maxSelectAll;
  }

  get someSelected() {
    const size = this.selectedProducts.length;
    return size > 0 && size < this.maxSelectAll;
  }

  get selectTooltip() {
    const size = this.selectedProducts.length;
    return size > 0 ? 'deselectAll' : 'selectAllPages';
  }

  gridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
  }
  rowSelected(event: RowSelectedEvent) {
    const selectedProductIndex = this.selectedProducts.findIndex(
      (product) => product.id === event.data.id
    );
    if (event.node.isSelected()) {
      if (selectedProductIndex === -1) {
        this.selectedProducts.push({ ...event.data });
      }
    } else {
      if (selectedProductIndex > -1) {
        this.selectedProducts.splice(selectedProductIndex, 1);
      }
    }
  }
  modelUpdated(event: ModelUpdatedEvent) {
    event.api.forEachNode((node) => {
      const isSelected = node.data && node.data.id && this.isSelectedProduct(node.data.id);
      node.setSelected(isSelected);
    });
  }
  updateGrid() {
    this.modelUpdated({api: this.gridApi} as unknown as ModelUpdatedEvent);
  }
  isSelectedProduct(productId: number): boolean {
    return (
      this.selectedProducts.findIndex((product) => product.id === productId) !==
      -1
    );
  }

  clickCSV(name: string) {
    const input = document.getElementById("fileCSV");
    input.dataset.selected = name;
    input.click();
  }
  chooseCSV(event: Event) {
    if (typeof window.FileReader !== 'function')
      throw ("The file API isn't supported on this browser.");
    const input = event.target as HTMLInputElement;
    const selected = input.dataset.selected;
    const rd = new FileReader();
    rd.onload = () => {
      this.parseCSV(selected, rd.result.toString());
    };
    rd.readAsText(input.files[0]);
  }
  parseCSV(selected: string, content: string) {
    const input = document.getElementById('fileCSV') as HTMLInputElement;
    input.value = null;
    const list = content.split('\n')
      .map(line => line.split(','));
    const csvList = [].concat(...list) as string[];
    const model = this.gridApi.getFilterModel() || {};
    const selectedModel = model[selected]
    const oldList = selectedModel?.filter?.split(',') || [];
    const newList = [
      ...oldList,
      ...csvList
    ].map(s => s.trim());
    model[selected] = {
      filterType: 'text',
      type: 'contains',
      filter: Array.from(new Set(newList)).filter(Boolean).join(',')
    }
    this.gridApi.setFilterModel({...model});
  }

  gridProvider(list: ListParams) {
    this.lastList = list;
    const selectedIds = this.selectedProducts.map(p => p.id);
    const isSelectedSort = list.sortString?.startsWith('0%3D');
    const selectedSort = this.gridApi.getColumn('0')?.getSort();
    return this.productService.searchProducts({
      ...list,
      search: this.search,
      sortString: (isSelectedSort) ? null : list.sortString,
      selectedItems: selectedIds,
      selectedSort: isSelectedSort ? selectedSort : null
    }).pipe(
      map((page, index) => {
        const selected = page.pageItems
          .filter(p => selectedIds.indexOf(p.id) >= 0);
        const notSelected = page.pageItems
          .filter(p => selectedIds.indexOf(p.id) == -1);
        const pageItems= [
          ...selected,
          ...notSelected
        ]
        return {
          pageItems,
          count: page.count,
        } as ProductPaginated;
      })
    );
  }
  selectAll() {
    const list: ListParams = {
      ...(this.lastList || {firstResult: 0, pageSize: 100}),
      firstResult: 0,
      pageSize: this.maxSelectAll,
    };
    const selectedSort = this.gridApi.getColumn('0')?.getSort();
    this.productService.searchProducts({
      ...list,
      search: this.search,
      sortString: (list.sortString?.startsWith('0%3D')) ? null : list.sortString,
      selectedSort,
      selectedItems: this.selectedProducts.map(p => p.id),
    }).subscribe(page => {
      const selectedIds = this.selectedProducts.map(p => p.id);
      this.selectedProducts.push(...page.pageItems
        .filter(p => selectedIds.indexOf(p.id) == -1)
      );
      this.updateGrid();
      if (page.count > this.maxSelectAll) {
        this.toastService.info(
          this.selectAllOverflowMessage.replace(/MAX_SELECTED/g, this.maxSelectAll.toString()),
          this.translateService.instant('selectAllOverflowTitle')
        );
      }
    });
  }
  selectNone() {
    this.selectedProducts = [];
    this.updateGrid();
  }
  toggleSelection() {
    const size = this.selectedProducts.length;
    if (size > 0) {
      this.selectNone();
    } else {
      this.selectAll();
    }
    this.selectAllCheckbox.checked = this.allSelected;
  }
}

export function productSearchDialog(
  modalService: ModalService,
  title: string,
  submitBtn: string,
  cancelBtn: string,
  maxSelected: number,
): Observable<any> {
  const modalData: ModalData = {
    type: EModalType.searchProducts,
    title,
    data: { maxSelected },
    submitBtn: {
      label: submitBtn,
    },
    cancelBtn: {
      label: cancelBtn,
    },
    component: ProductSearchDialogComponent
  }
  const dialogRef = modalService.openDefaultDialog(
    modalData,
    undefined,
    undefined,
    undefined,
    DialogWidth.DEFAULT,
    DialogHeight.DEFAULT);
  return dialogRef.afterClosed();
}

export function productSearchMerge<T extends {}, ID>(
  oldList: T[],
  newList: T[],
  idGetter: (src: T) => ID): T[] {
  const oldIds = oldList.map(p => idGetter(p));
  const newIds = newList.map(p => idGetter(p));
  const resList = Array.from(new Set([
    ...oldIds,
    ...newIds,
  ]))
    .map(id =>
      oldList.find(p => idGetter(p) == id) ??
      newList.find(p => idGetter(p) == id)
    );
  return resList;
}

