import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {
  FilterLink,
  FilterType,
  PresetFilter,
  TablePresetFilterService
} from '../../services/table-preset-filter.service';
import {GlobalService} from '../../services/global.service';
import {ECodeTables, EViewRoutes} from '../../util/enum';
import {CodeTableService} from '../../services/code-table.service';
import {combineLatest, map, Observable, of} from 'rxjs';
import {CodeTableEntry, IdentService,} from '../../api/core';

interface Tuple {
  key: string;
  value: string;
}

interface RedirectArg {
  name: string;
  target: string;
  type: FilterType;
}
type RedirectTarget = 'campaign-overview' |
  'campaign-overview-flat' |
  'campaign-list' |
  'portfolio-overview' |
  'portfolio-list' |
  'campaign-intermediaries' |
  'client-list' |
  'client-overview' |
  'employee-list' |
  'employee-overview' |
  'intermediary-list' |
  'intermediary-overview';
interface RedirectConfig {
  target: RedirectTarget;
  targetRoute: EViewRoutes;
  targetTableId?: string;
  filters?: RedirectArg[];
  params?: RedirectArg[];
}

const config: RedirectConfig[] = [
  {
    target: 'campaign-list',
    targetTableId: 'campaign-catalogue',
    targetRoute: EViewRoutes.campaigns,
    filters: [
      {name: 'name', target: 'name', type: 'TEXT'},
      {name: 'story', target: 'story.name', type: 'TEXT'},
      {name: 'status', target: 'status', type: 'ENUM'},
      {name: 'processing', target: 'processing', type: 'ENUM'},
      {name: 'createdAt', target: 'createdAt', type: 'TEXT'},
      {name: 'validFrom', target: 'validFrom', type: 'TEXT'},
      {name: 'validTo', target: 'validTo', type: 'TEXT'},
    ]
  },
  {
    target: 'portfolio-list',
    targetTableId: 'portfolio-list',
    targetRoute: EViewRoutes.portfolios,
    filters: [
      {name: 'number', target: 'number', type: 'TEXT'},
      {name: 'portfolioType', target: 'portfolioType', type: 'ENUM'}, //this is working with the IDs
      {name: 'advisoryType', target: 'advisoryType', type: 'ENUM'},//ID
      {name: 'portfolioStrategy', target: 'strategy', type: 'ENUM'},//ID
      {name: 'currency', target: 'referenceCurrency.ident', type: 'TEXT'},
      {name: 'value', target: 'portfolioValue', type: 'NUMBER'},
      {name: 'risk', target: 'risk', type: 'NUMBER'},
      {name: 'riskMin', target: 'riskSpreadMin', type: 'NUMBER'},
      {name: 'riskMax', target: 'riskSpreadMax', type: 'NUMBER'},
      {name: 'riskState', target: 'riskState', type: 'ENUM'},
      {name: 'bpNumber', target: 'bpNumber', type: 'TEXT'},
      {name: 'language', target: 'preferredLanguage', type: 'ENUM'},
      {name: 'relationshipManager', target: 'relationshipManager.username', type: 'ENUM'},
      {name: 'advisor', target: 'advisor.username', type: 'ENUM'},
      {name: 'clientRole', target: 'clients.role', type: 'ENUM'},
      {name: 'optOut', target: 'optOut', type: 'ENUM'},
      {name: 'closed', target: 'closed', type: 'ENUM'},
    ]
  },
  {
    target: 'campaign-overview',
    targetTableId: 'campaign-portfolio-list',
    targetRoute: EViewRoutes.campaignOverview,
    params: [
      {name: 'ident', target: 'ident', type: 'TEXT'}
    ],
    filters: [
      {name: 'portfolioNumber', target: 'portfolioNumber', type: 'TEXT'},
      {name: 'done', target: 'done', type: 'ENUM'},
      {name: 'portfolioValue', target: 'portfolioValue', type: 'NUMBER'},
      {name: 'currency', target: 'portfolioCurrency.ident', type: 'TEXT'},
      {name: 'bpNumber', target: 'portfolio.bpNumber', type: 'TEXT'},
      {name: 'portfolioType', target: 'portfolioType', type: 'ENUM'},
      {name: 'advisoryType', target: 'portfolioAdvisoryType', type: 'ENUM'},
      {name: 'portfolioStrategy', target: 'portfolioStrategy', type: 'ENUM'},
      {name: 'relationshipManager', target: 'portfolio.relationshipManager.username', type: 'ENUM'},
      {name: 'advisor', target: 'portfolio.advisor.username', type: 'ENUM'},
      {name: 'assignee', target: 'campaignActions.assignee.username', type: 'ENUM'},
      {name: 'risk', target: 'portfolio.risk', type: 'NUMBER'},
      {name: 'riskMin', target: 'portfolio.riskSpreadMin', type: 'NUMBER'},
      {name: 'riskMax', target: 'portfolio.riskSpreadMax', type: 'NUMBER'},
      {name: 'riskState', target: 'portfolioRiskState', type: 'ENUM'},
      {name: 'suitability', target: 'combinedSuitabilityState', type: 'ENUM'},
    ]
  },
  {
    target: 'campaign-overview-flat',
    targetTableId: 'campaign-portfolio-list',
    targetRoute: EViewRoutes.campaignOverview,
    params: [
      {name: 'ident', target: 'ident', type: 'TEXT'}
    ],
    filters: [
      {name: 'portfolioNumber', target: 'portfolio.number', type: 'TEXT'},
      {name: 'preferredLanguage', target: 'portfolio.preferredLanguage', type: 'ENUM'},
      {name: 'actualLanguage', target: 'language', type: 'ENUM'},
      {name: 'bpNumber', target: 'portfolio.bpNumber', type: 'TEXT'},
      {name: 'channelType', target: 'channel.type', type: 'ENUM'},
      {name: 'relationshipManager', target: 'portfolio.relationshipManager.username', type: 'ENUM'},
      {name: 'advisor', target: 'portfolio.advisor.username', type: 'ENUM'},
      {name: 'assignee', target: 'assignee.username', type: 'ENUM'},
      {name: 'sender', target: 'sender.username', type: 'ENUM'},
      {name: 'suitability', target: 'suitability', type: 'ENUM'},
      {name: 'status', target: 'status', type: 'ENUM'},
      {name: 'viewed', target: 'viewed', type: 'ENUM'},
    ]
  },
  {
    target: 'portfolio-overview',
    targetRoute: EViewRoutes.portfolioOverview,
    params: [
      {name: 'ident', target: 'ident', type: 'TEXT'}
    ],
  },
  {
    target: 'campaign-intermediaries',
    targetTableId: 'intermediary-list',
    targetRoute: EViewRoutes.campaignOverview,
    filters: [
      {name: 'name', target: 'name', type: 'TEXT'},
      {name: 'externalKey', target: 'externalKey', type: 'TEXT'},
      {name: 'intermediaryType', target: 'type', type: 'ENUM'},
      {name: 'hub', target: 'hub', type: 'ENUM'},
      {name: 'leadRelationshipManager', target: 'leadRelationshipManager.username', type: 'ENUM'},
      {name: 'closed', target: 'closed', type: 'ENUM'},
    ]
  },
  {
    target: 'client-list',
    targetRoute: EViewRoutes.clients,
  },
  {
    target: 'client-overview',
    targetRoute: EViewRoutes.clientOverview,
    params: [
      {name: 'ident', target: 'ident', type: 'TEXT'}
    ],
  },
  {
    target: 'employee-list',
    targetRoute: EViewRoutes.employees,
  },
  {
    target: 'employee-overview',
    targetRoute: EViewRoutes.employeeOverview,
    params: [
      {name: 'ident', target: 'ident', type: 'TEXT'}
    ],
  },
  {
    target: 'intermediary-list',
    targetRoute: EViewRoutes.intermediaries,
  },
  {
    target: 'intermediary-overview',
    targetRoute: EViewRoutes.intermediaryOverview,
    params: [
      {name: 'ident', target: 'ident', type: 'TEXT'}
    ],
  },
];

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
})
export class RedirectComponent implements OnInit {

  currencies: CodeTableEntry[];
  portfolioTypes: CodeTableEntry[];
  portfolioStrategies: CodeTableEntry[];
  advisoryTypes: CodeTableEntry[];
  riskStates: CodeTableEntry[];
  languages: CodeTableEntry[];
  clientRoles: CodeTableEntry[];
  viewedStatus: CodeTableEntry[];
  channelTypes: CodeTableEntry[];
  hubs: CodeTableEntry[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private tablePresetFilterService: TablePresetFilterService,
    private globalService: GlobalService,
    private codeTableService: CodeTableService,
    private identService: IdentService,
  ) {
    this.initCodeTables();
  }

  private initCodeTables() {
    combineLatest([
      this.codeTableService.getCodeTable(ECodeTables.currency),
      this.codeTableService.getCodeTable(ECodeTables.portfolioType),
      this.codeTableService.getCodeTable(ECodeTables.portfolioStrategy),
      this.codeTableService.getCodeTable(ECodeTables.advisoryType),
      this.codeTableService.getCodeTable(ECodeTables.riskState),
      this.codeTableService.getCodeTable(ECodeTables.language),
      this.codeTableService.getCodeTable(ECodeTables.clientRole),
      this.codeTableService.getCodeTable(ECodeTables.viewedStatus),
      this.codeTableService.getCodeTable(ECodeTables.channelType),
      this.codeTableService.getCodeTable(ECodeTables.hub),
    ]).subscribe({
      next: (data) => {
        let k = 0;
        this.currencies = data[k++];
        this.portfolioTypes = data[k++];
        this.portfolioStrategies = data[k++];
        this.advisoryTypes = data[k++];
        this.riskStates = data[k++];
        this.languages = data[k++];
        this.clientRoles = data[k++];
        this.viewedStatus = data[k++];
        this.channelTypes = data[k++];
        this.hubs = data[k++];
      },
    });
  }

  /**
   * Types of direct links:
   * - Links for specific item: campaign/overview/:campaignId
   * - Links for catalogue items: campaign/all
   * - Links for specific item with filters (plural) pre-set
   * - Links for catalogue items with filters (plural) pre-set
   *
   */
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      const conf = config.filter((d) => d.target === queryParams.target).shift();
      if (conf) {
        const filters = this.parseQueryParams(queryParams.filters);
        const params = this.parseQueryParams(queryParams.params);
        const qParams = this.generateQueryParams(conf, filters);
        this.generateRoute(conf, params)
          .subscribe((r) => {
            this.globalService.navigate(r, qParams);
          });
      } else {
        this.globalService.navigate(EViewRoutes.home);
      }
    });
  }

  private parseQueryParams(str: string): Tuple[] {
    if (str && str.length > 0) {
      return decodeURIComponent(str)
        .replace(/['"]+/g, '')
        .split(';')
        .map((d) => {
          const parts = d.split('=');
          return {key: parts[0], value: parts[1]};
        }).filter((d) => d.value);
    }
    return [];
  }

  private generateRoute(conf: RedirectConfig, params: Tuple[]): Observable<string> {
    const ident = params.filter((d) => d.key === 'ident').map((d) => d.value).shift();
    const targetParts = conf.target.split('-');
    const element = targetParts[0] as any;
    const isListRoute = targetParts[1] === 'list';
    if (!isListRoute && ident) {
      switch (conf.target) {
        case 'campaign-overview-flat':
          localStorage.setItem('campaignOverviewTab', 'flat');
          break;
        case 'campaign-overview':
          localStorage.setItem('campaignOverviewTab', 'tree');
          break;
        case 'campaign-intermediaries':
          localStorage.setItem('campaignOverviewTab', 'intermediaries');
      }
      if (conf.target === 'campaign-overview-flat') {
        localStorage.setItem('campaignOverviewFlat', '1');
      }
      return this.identService.getElementId(element, ident)
        .pipe(map((d) => `${conf.targetRoute}${d.id}`));
    }
    return of(conf.targetRoute);
  }

  private generateQueryParams(conf: RedirectConfig, filters: Tuple[]): Params {
    if (conf.targetTableId) {
      const presetFilter = this.tablePresetFilterService.mergePresetFilters(
        filters.map((d) => {
          const filterConfig = conf.filters.filter((it) => it.name === d.key).shift();
          if (filterConfig) {
            return this.filterOf(filterConfig, d);
          }
          return undefined;
        })
          .filter((d) => d));
      this.tablePresetFilterService.saveTargetState(
        presetFilter.filterModel,
        null,
        presetFilter.stateId
      );
      const result: Params = {};
      result[`${conf.targetTableId}State`] = presetFilter.stateId;
      return result;
    } else {
      return {};
    }
  }

  private filterOf(filterConfig: RedirectArg, tuple: Tuple): PresetFilter {
    let filter: FilterLink;

    switch (filterConfig.type) {
      case 'ENUM':
        filter = {
          name: filterConfig.target,
          value: this.asArray(this.mapEnumValue(filterConfig.name, tuple.value), tuple.value),
          filterType: filterConfig.type,
          valueNames: [],
        };
        break;
      case 'NUMBER':
        filter = {
          name: filterConfig.target,
          value: tuple.value,
          filterType: filterConfig.type,
          type: 'equals',
        };
        break;
      default:
        filter = {
          name: filterConfig.target,
          value: tuple.value,
          filterType: filterConfig.type,
          type: 'contains',
        };
        break;
    }
    return this.tablePresetFilterService.genFilter(filter);
  }

  private asArray(src: string | number | string[] | undefined, defItem: string): string[] {
    if (Array.isArray(src)) return src;
    if (src == undefined) return  [defItem];
    return [src].map(s => s.toString());
  }

  private mapEnumValue(name: string, rawValue: string) {
    let result: number[];
    const value = rawValue.toUpperCase();
    const values = value.split(',')
    const findId = (list: any[]) =>
      list.filter((d) => values.includes(d.ident.toUpperCase())).map((d) => d.id);
    switch (name) {
      case 'currency':
        result = findId(this.currencies);
        break;
      case 'portfolioType':
        result = findId(this.portfolioTypes);
        break;
      case 'advisoryType':
        result = findId(this.advisoryTypes);
        break;
      case 'portfolioStrategy':
        result = findId(this.portfolioStrategies);
        break;
      case 'riskState':
        result = findId(this.riskStates);
        break;
      case 'language':
      case 'preferredLanguage':
      case 'actualLanguage':
        result = findId(this.languages);
        break;
      case 'clientRole':
        result = findId(this.clientRoles);
        break;
      case 'viewed':
        result = findId(this.viewedStatus);
        break;
      case 'channelType':
        result = findId(this.channelTypes);
        break;
      case 'assignee':
      case 'sender':
      case 'relationshipManager':
      case 'suitability':
      case 'status':
        return rawValue.split(',')
      case 'intermediaryType':
        return rawValue.split(',');
      default:
        result = undefined;
        break;
    }
    return result ? result.map(n => n.toString()) : undefined;
  }

}
