export * from './advisors.service';
import { AdvisorsService } from './advisors.service';
export * from './appMessage.service';
import { AppMessageService } from './appMessage.service';
export * from './asset.service';
import { AssetService } from './asset.service';
export * from './business.service';
import { BusinessService } from './business.service';
export * from './campaign.service';
import { CampaignService } from './campaign.service';
export * from './campaignAction.service';
import { CampaignActionService } from './campaignAction.service';
export * from './campaignIntermediary.service';
import { CampaignIntermediaryService } from './campaignIntermediary.service';
export * from './campaignIntermediaryAction.service';
import { CampaignIntermediaryActionService } from './campaignIntermediaryAction.service';
export * from './carbonCopy.service';
import { CarbonCopyService } from './carbonCopy.service';
export * from './channel.service';
import { ChannelService } from './channel.service';
export * from './client.service';
import { ClientService } from './client.service';
export * from './codeTables.service';
import { CodeTablesService } from './codeTables.service';
export * from './collection.service';
import { CollectionService } from './collection.service';
export * from './content.service';
import { ContentService } from './content.service';
export * from './currency.service';
import { CurrencyService } from './currency.service';
export * from './document.service';
import { DocumentService } from './document.service';
export * from './employee.service';
import { EmployeeService } from './employee.service';
export * from './employeeCollection.service';
import { EmployeeCollectionService } from './employeeCollection.service';
export * from './export.service';
import { ExportService } from './export.service';
export * from './features.service';
import { FeaturesService } from './features.service';
export * from './filter.service';
import { FilterService } from './filter.service';
export * from './gui.service';
import { GuiService } from './gui.service';
export * from './ident.service';
import { IdentService } from './ident.service';
export * from './info.service';
import { InfoService } from './info.service';
export * from './intermediary.service';
import { IntermediaryService } from './intermediary.service';
export * from './license.service';
import { LicenseService } from './license.service';
export * from './monitoring.service';
import { MonitoringService } from './monitoring.service';
export * from './orgPosition.service';
import { OrgPositionService } from './orgPosition.service';
export * from './outgoingMessage.service';
import { OutgoingMessageService } from './outgoingMessage.service';
export * from './portfolio.service';
import { PortfolioService } from './portfolio.service';
export * from './product.service';
import { ProductService } from './product.service';
export * from './relationshipManager.service';
import { RelationshipManagerService } from './relationshipManager.service';
export * from './salutation.service';
import { SalutationService } from './salutation.service';
export * from './sender.service';
import { SenderService } from './sender.service';
export * from './sentMessage.service';
import { SentMessageService } from './sentMessage.service';
export * from './story.service';
import { StoryService } from './story.service';
export * from './systemStatus.service';
import { SystemStatusService } from './systemStatus.service';
export * from './tag.service';
import { TagService } from './tag.service';
export * from './templateDefault.service';
import { TemplateDefaultService } from './templateDefault.service';
export * from './user.service';
import { UserService } from './user.service';
export const APIS = [AdvisorsService, AppMessageService, AssetService, BusinessService, CampaignService, CampaignActionService, CampaignIntermediaryService, CampaignIntermediaryActionService, CarbonCopyService, ChannelService, ClientService, CodeTablesService, CollectionService, ContentService, CurrencyService, DocumentService, EmployeeService, EmployeeCollectionService, ExportService, FeaturesService, FilterService, GuiService, IdentService, InfoService, IntermediaryService, LicenseService, MonitoringService, OrgPositionService, OutgoingMessageService, PortfolioService, ProductService, RelationshipManagerService, SalutationService, SenderService, SentMessageService, StoryService, SystemStatusService, TagService, TemplateDefaultService, UserService];
