<app-tag class="primary">
  <div>{{ label }}</div>
  <app-config-icon
    iconIdentifier="delete"
    class="mat-icon"
    (click)="onDeleteClick()"
    *ngIf="!readOnly"
  ></app-config-icon>
</app-tag>
<div class="filter-content">
  <div class="exposure-label">
    <mat-slide-toggle
      color="primary"
      labelPosition="before"
      [checked]="hasExposure"
      (toggleChange)="toggleHasExposure()"
      [disabled]="readOnly"
      *ngIf="
        (fields.exposure | isFilterRangeWeightFilerActive: filterCategory) &&
        (fields.weight | isFilterRangeWeightFilerActive: filterCategory)
      "
      >{{ 'exposure' | translate }}</mat-slide-toggle
    >
  </div>
  <mat-form-field
    *ngIf="
      !hasExposure && fields.weight
        | isFilterRangeWeightFilerActive: filterCategory
    "
  >
    <mat-label>{{ 'weight' | translate }}</mat-label>
    <mat-select
      color="accent"
      (selectionChange)="onWeightSelectChange()"
      [(ngModel)]="weightSelect"
      [disabled]="readOnly"
    >
      <mat-option [value]="undefined"></mat-option>
      <mat-option
        *ngFor="let option of weightOptions; trackBy: trackByFn"
        [value]="option"
        >{{ option | translate }}</mat-option
      >
    </mat-select>
  </mat-form-field>
  <div class="exposure-slider" *ngIf="hasExposure">
    <ngx-slider
      class="primary"
      [(value)]="rangeSlider.minValue"
      [(highValue)]="rangeSlider.maxValue"
      [(options)]="rangeSlider.options"
      (userChangeEnd)="onRangeSliderChange($event)"
    ></ngx-slider>
  </div>
</div>
