import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { catchError, repeat, retry, tap } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import {ProgressSocket} from "./progress-socket";

export class CampaignProcessingProgressSocket extends ProgressSocket<CampaignProcessingProgress> {
  constructor(campaignId: number, accessToken: string) {
    super(`campaignProgress/${campaignId}`, accessToken);
  }
}

export interface CampaignProcessingProgress {
  campaignId: number;
  processing: boolean;
  error: boolean;
  current: number;
  total: number;
  processingAction: string;
  processingMessage: string;
}
