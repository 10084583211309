import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ISliderOptions } from 'src/app/models/slider-options.model';
import { ChangeContext } from 'ngx-slider-v2';
import { FilterMetaData } from '../../../models/filter-meta-data.model';
import { EFilterSubType, EFilterType } from '../../../util/enum';
import { AssetRange } from 'src/app/models/filter.model';

type AsEnum = 'value' | 'percentage';
const asEnum = {
  Value: 'value' as AsEnum,
  Percentage: 'percentage' as AsEnum,
};

/**
 * Range Weight Filter Component.
 * Component for combination of range and weight filter.
 */
@Component({
  selector: 'app-filter-asset',
  templateUrl: './filter-asset.component.html',
})
export class FilterAssetComponent implements OnInit {
  /**
   * Component input to set filter value
   */
  @Input() filter: AssetRange;

  @Input() readOnly = false;
  /**
   * Component output to notifiy that filter should be deleted
   */
  @Output() delete: EventEmitter<any> = new EventEmitter<any>();

  /**
   * @angular-slider/ngx-slider range slider options
   */
  rangeSlider: ISliderOptions;
  /**
   * Flag to mark to show/hide range slider
   */
  asSelect: AsEnum;
  asOptions: AsEnum[] = Object.values(asEnum);
  assetName = '';
  assetLabel = '';
  minValue = 0;
  maxValue = 1;
  percMeta: FilterMetaData = new FilterMetaData(
    EFilterType.range,
    '',
    EFilterSubType.rangeMinMaxPercentage
  );
  valueMeta: FilterMetaData = new FilterMetaData(
    EFilterType.range,
    '',
    EFilterSubType.rangeMinMax
  );

  constructor() {}

  ngOnInit(): void {
    this.fromFilter();
  }

  private fromFilter() {
    this.assetName = this.filter.key;
    this.assetLabel = this.filter.name;
    this.asSelect = this.filter.isValue ? 'value' : 'percentage';
    if (this.filter.isValue) {
      this.minValue = this.filter.range.min ?? 0;
      this.minValue = this.filter.range.max ?? 1;
    } else {
      this.rangeSlider = this.createRangeSliderConfig(
        this.filter.range.min ? this.filter.range.min * 100 : 0,
        this.filter.range.max ? this.filter.range.max * 100 : 100,
        this.readOnly
      );
    }
  }

  /**
   * Change handler for @angular-slider/ngx-slider range filter
   * @param changeContext @angular-slider/ngx-slider change event context
   */
  onRangeSliderChange(changeContext: ChangeContext): void {
    this.filter.range = {
      max: changeContext.highValue / 100,
      min: changeContext.value / 100,
    };
  }

  /**
   * Generate initial @angular-slider/ngx-slider options
   * @param min Min value of slider to overwrite default 0
   * @param max Max value of slider to overwrite default 100
   * @param readOnly
   */
  private createRangeSliderConfig(
    min: number,
    max: number,
    readOnly: boolean
  ): ISliderOptions {
    return {
      minValue: min,
      maxValue: max,
      options: {
        floor: 0,
        ceil: 100,
        step: 1,
        disabled: readOnly,
        translate: (value: number): string => value + '%',
      },
    };
  }

  onAsChanged(): void {
    this.filter.isValue = this.asSelect === 'value';
  }

  /**
   * Click handler on delete icon
   */
  onDeleteClick(): void {
    this.delete.emit();
  }

  /**
   * Helper funtion to track item during iteration over items
   * @param index Index of item
   * @param item Iterated item
   */
  trackByFn(index: number, item: any): number {
    return index;
  }

  clamp(low: number, high: number, value: number): number {
    return Math.min(high, Math.max(low, value));
  }

  formHasErrors(hasErrors: boolean): void {
    this.filter.formHasErrors = hasErrors;
  }
}
