<div *ngIf="client" class="page-content-container">
  <app-sub-header
    [header]="client.personNumber"
    [breadcrumbs]="['distributor', 'clientOverview']"
  >
  </app-sub-header>

  <div class="distributor-details-container aspark-container-padding">
    <app-client-overview-details
      [client]="client"
      class="details"
    ></app-client-overview-details>
    <app-client-publication-types
      class="publication-types"
      [clientId]="client.id"
    ></app-client-publication-types>
    <app-card class="grid-card">
      <div card-header>
        <span>{{ 'portfolioList' | translate }}</span>
      </div>
      <div card-body>
        <app-client-overview-portfolios
          [client]="client"
          [fromDistributor]="true"
        ></app-client-overview-portfolios>
      </div>
    </app-card>
    <app-distributor-summary
      *ngIf="summary"
      [summary]="summary"
      [showReceived]="true"
      [showViewed]="true"
      [showMonitoringValues]="true"
      [goBackRoute]="EViewRoutes.distributorClients"
      [goBackLinkMsgKey]="'viewAllClients'"
    >
    </app-distributor-summary>

    <div class="full-row">
      <app-grid
        tableId="distributor-client-overview-actions"
        [rowData]="actions"
        [columnDefs]="colDefs"
        [gridOptions]="gridOptions"
      ></app-grid>
    </div>
  </div>

</div>
