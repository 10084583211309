import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ISliderOptions } from 'src/app/models/slider-options.model';
import { ChangeContext } from 'ngx-slider-v2';
import { WeightRange } from 'src/app/models/filter.model';
import { EFilterCategories, ERangeWeightFilter } from 'src/app/util/enum';
import { IsFilterRangeWeightFilerActivePipe } from '../../shared.pipe';

/**
 * Range Weight Filter Component.
 * Component for combination of range and weight filter.
 */
@Component({
  selector: 'app-filter-range-weight',
  templateUrl: './filter-range-weight.component.html',
})
export class FilterRangeWeightComponent implements OnInit {
  /**
   * Component input to label
   */
  @Input() label: string;

  @Input() readOnly = false;
  /**
   * Component input to set filter value
   */
  @Input() filter: WeightRange;
  /**
   * Filter category that the filter belongs to
   */
  @Input() filterCategory: EFilterCategories; // TODO: make really configurable, this is temporarily used to make some filters unavailable
  /**
   * Component output to notifiy that filter should be deleted
   */
  @Output() delete: EventEmitter<any> = new EventEmitter<any>();

  /**
   * @angular-slider/ngx-slider range slider options
   */
  rangeSlider: ISliderOptions;
  /**
   * Flag to mark to show/hide range slider
   */
  hasExposure = false;
  /**
   * Local value for weight select field
   */
  weightSelect: WeightRange.WeightEnum;
  /**
   * Possible weight options
   */
  weightOptions: WeightRange.WeightEnum[] = Object.values(
    WeightRange.WeightEnum
  );

  constructor(
    protected isFilterRangeWeightFilterActivePipe: IsFilterRangeWeightFilerActivePipe
  ) {}

  ngOnInit(): void {
    this.weightSelect = this.filter.weight;
    if (this.filter.range?.max == null && this.filter.range?.min == null) {
      this.rangeSlider = this.createRangeSliderConfig(0, 100, this.readOnly);
    } else {
      this.hasExposure = true;
      this.rangeSlider = this.createRangeSliderConfig(
        this.filter.range.min ? this.filter.range.min * 100 : 0,
        this.filter.range.max ? this.filter.range.max * 100 : 0,
        this.readOnly
      );
    }
    if (
      !this.isFilterRangeWeightFilterActivePipe.transform(
        this.fields.weight,
        this.filterCategory
      ) &&
      this.isFilterRangeWeightFilterActivePipe.transform(
        this.fields.exposure,
        this.filterCategory
      )
    ) {
      this.hasExposure = true;
      this.filter.range.max = this.rangeSlider.maxValue / 100;
      this.filter.range.min = this.rangeSlider.minValue / 100;
      this.filter.weight = undefined;
    }
  }

  /**
   * Provides range weight filter enum to html
   */
  get fields() {
    return ERangeWeightFilter;
  }

  /**
   * Change handler for @angular-slider/ngx-slider range filter
   * @param changeContext @angular-slider/ngx-slider change event context
   */
  onRangeSliderChange(changeContext: ChangeContext): void {
    this.filter.range = {
      max: changeContext.highValue / 100,
      min: changeContext.value / 100,
    };
  }

  /**
   * Change handler for filter with select field
   */
  onWeightSelectChange(): void {
    this.filter.weight = this.weightSelect;
  }

  /**
   * Generate initial @angular-slider/ngx-slider options
   * @param min Min value of slider to overwrite default 0
   * @param max Max value of slider to overwrite default 100
   * @param isNull Flag is min/max inputs are null (needed to allow 0 min/max values)
   */
  private createRangeSliderConfig(
    min: number,
    max: number,
    readOnly: boolean
  ): ISliderOptions {
    return {
      minValue: min,
      maxValue: max,
      options: {
        floor: 0,
        ceil: 100,
        step: 1,
        disabled: readOnly,
        translate: (value: number): string => value + '%',
      },
    };
  }

  /**
   * Toggles hasExposure flag
   */
  toggleHasExposure(): void {
    this.hasExposure = !this.hasExposure;
    if (this.hasExposure) {
      this.filter.range.max = this.rangeSlider.maxValue / 100;
      this.filter.range.min = this.rangeSlider.minValue / 100;
      this.filter.weight = undefined;
    } else {
      this.filter.range.max = null;
      this.filter.range.min = null;
    }
  }

  /**
   * Click handler on delete icon
   */
  onDeleteClick(): void {
    this.delete.emit();
  }

  /**
   * Helper funtion to track item during iteration over items
   * @param index Index of item
   * @param item Iterated item
   */
  trackByFn(index: number, item: any): number {
    return index;
  }
}
